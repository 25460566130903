import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactSelect from "react-select";
import {
  CountryInput,
  DateInput,
  NumberInput,
  TextInput,
  TwoButtons,
} from "../../../../components";
import axios from "axios";
import { updatedAttributes } from "../../../../store/actions/products";
import {useSelector } from "react-redux";
const moment = require("moment");

const PolicyMember = ({
  inputs,
  handleChangePolicy,
  calculateMinDate,
  riskAtrributes,
  dispatch,
  calculateMaxDate,
  errors,
  customStyles,
  width,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const MAMRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const getAttributes = (value) => {
    return MAMRiskAttributes.find((x) => x.name === value);
  };
  useEffect(() => {
    (async () => {
      const res = await axios.get('https://restcountries.com/v3.1/all')
      var countArray = res.data.sort((a, b) => a.name.common.toLowerCase() > b.name.common.toLowerCase() ? 1 : -1).filter((f) => f.name.common.toLowerCase() !== 'nigeria').map((d) => ({ label: d.name.common, value: d.name.common }))
      setCountries([
        {
         label: 'Nigeria',
         value: 'Nigeria'
         
        },
        ...countArray
      ])
    })()
  }, []);

  useEffect(() => {
    setStates([		
            {label: "Abia", value: "Abia",},
            {label: "Adamawa", value: "Adamawa",},
            {label: "Akwa Ibom",value: "Akwa Ibom",},
            {label: "Anambra",value: "Anambra",},
            {label: "Bauchi", value: "Bauchi",},
            {label: "Bayelsa", value: "Bayelsa",},
            {label: "Benue",value: "Benue",},
            {label: "Borno",value: "Borno",},
            {label: "Cross River", value: "Cross River",},
            {label: "Delta", value: "Delta",},
            {label: "Ebonyi",value: "Ebonyi",},
            {label: "Edo",value: "Edo",},
            {label: "Ekiti", value: "Ekiti",},
            {label: "Enugu", value: "Enugu",},
            {label: "Fct",value: "Fct",},
            {label: "Gombe",value: "Gombe",},
            {label: "Imo", value: "Imo",},
            {label: "Jigawa", value: "Jigawa",},
            {label: "Kaduna",value: "Kaduna",},
            {label: "Kano",value: "Kano",},
            {label: "Katsina", value: "Katsina",},
            {label: "Kebbi", value: "Kebbi",},
            {label: "Kogi",value: "Kogi",},
            {label: "Kwara",value: "Kwara",},
            {label: "Lagos", value: "Lagos",},
            {label: "Nasarawa", value: "Nasarawa",},
            {label: "Niger",value: "Niger",},
            {label: "Ogun",value: "Ogun",},
            {label: "Ondo", value: "Ondo",},
            {label: "Osun", value: "Osun",},
            {label: "Others",value: "Others",},
            {label: "Oyo",value: "Oyo",},
            {label: "Plateau", value: "Plateau",},
            {label: "Rivers", value: "Rivers",},
            {label: "Sokoto",value: "Sokoto",},
            {label: "Taraba",value: "Taraba",},
            {label: "Yobe", value: "Yobe",},
            {label: "Zamfara", value: "Zamfara",},

   ])
  },[])
  const auth = useSelector((state) => state?.auth.refererData.customer);

  return (
    <Accordion
      defaultExpanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="d-flex flex-row">
          <div
            style={{
              borderRadius: 99,
              marginRight: 10,
              backgroundColor: "white",
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="primary-color f-700" id="accord-number">
              1
            </span>
          </div>
          <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
            Add policy holder details here
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails className="p-0 mt-4">
        <div
          style={{
            border: "1px solid #00000030",
            padding: 20,
            borderRadius: 5,
          }}
        >
          <TextInput
            id={"firstName"}
            label={"First name"}
            name={"firstName"}
            value={inputs?.firstName}
            errors={errors?.["firstName"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={true}
          />
          <TextInput
            id={"lastName"}
            label={"Last name or surname"}
            name={"lastName"}
            value={inputs?.lastName}
            errors={errors?.["lastName"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={true}
          />
          <div className="form-group mt-3">
            <label className="form-label">Title*</label>
            <ReactSelect
              id={`title`}
              placeholder="title"
              styles={customStyles}
              value={
                inputs?.title?.value
                  ? inputs?.title?.value
                  : { label: inputs?.title, value: inputs?.title }
              }
              options={[
                {
                  label: "Adv",
                  value: "Adv",
                },
                {
                  label: "Dr",
                  value: "Dr",
                },
                {
                  label: "Miss",
                  value: "Miss",
                },
                {
                  label: "Mr",
                  value: "Mr",
                },
                {
                  label: "Mrs",
                  value: "Mrs",
                },
                {
                  label: "Ms",
                  value: "Ms",
                },
                {
                  label: "Prof",
                  value: "Prof",
                },
                {
                  label: "Rev",
                  value: "Rev",
                },
                {
                  label: "Sir",
                  value: "Sir",
                },
                {
                  label: "NONE",
                  value: "NONE",
                },
              ]}
              onChange={(e) => {
                handleChangePolicy({
                  target: { name: "title", value: e.value },
                });
              }}
            />
            {errors?.["title"] && (
              <span className="text-danger errors">
                {errors?.["title"] && errors?.["title"][0]}
              </span>
            )}
          </div>

          <TextInput
            id={"occupation"}
            label={"Occupation"}
            name={"occupation"}
            value={inputs?.occupation}
            errors={errors?.["occupation"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={true}
          />
          <TextInput
            id={"employer"}
            label={"Employer"}
            name={"employer"}
            value={inputs?.employer}
            errors={errors?.["employer"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={false}
          />

          <TextInput
            id={"position"}
            label={"Position / Grade / Level"}
            name={"position"}
            value={inputs?.position}
            errors={errors?.["position"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={false}
          />

          <TextInput
            id={"business"}
            label={"If business, type of business"}
            name={"business"}
            value={inputs?.business}
            errors={errors?.["business"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={false}
          />

            <div className="form-group mt-3">
                  <label className="form-label">Source of fund*</label>
                  <ReactSelect
                    id={`sourceOfFund`}
                    placeholder="Source of fund"
                    value={inputs?.sourceOfFund}
                    styles={customStyles}
                    options={JSON.parse(getAttributes('Source of Fund')?.settings)?.items?.map((f) => ({ label: f, value: f }))}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "sourceOfFund", value: e },
                      });
                    }}
                  />
                  {errors?.["sourceOfFund"] && (
                    <span className="text-danger errors">
                      {errors?.["sourceOfFund"] &&
                        errors?.["sourceOfFund"][0]}
                    </span>
                  )}
                </div>

          <DateInput
            id={"dob"}
            label={"Date of birth"}
            name={"dob"}
            minDate={calculateMinDate("parent")}
            errors={errors?.["dob"]}
            maxDate={calculateMaxDate("parent")}
            value={inputs?.dob}
            onChange={(e) => handleChangePolicy(e)}
            required={true}
            readOnly={true}
            disabled={true}
          />

          <div style={{ height: "20px" }} />
          <TwoButtons
            id={`mainGender`}
            title={"Gender"}
            isField={true}
            half={true}
            errors={errors?.[`mainGender`]}
            state={inputs?.mainGender}
            onClick={(e) =>
              handleChangePolicy({
                target: { name: "mainGender", value: e },
              })
            }
            options={[
              {
                name: "Male",
                value: "Male",
              },
              {
                name: "Female",
                value: "Female",
              },
            ]}
            required={true}
          />
          <div className="form-group mt-3">
            <label className="form-label">Marital status*</label>
            <ReactSelect
              id={`maritalStatus`}
              placeholder="Select status"
              styles={customStyles}
              value={inputs?.maritalStatus}
              options={[
                { value: "Married", label: "Married" },
                { value: "Single", label: "Single" },
                {value: 'Widower/Widowed', label: 'Widower/Widowed'},
                {value: 'Divorced', label: 'Divorced'}
              ]}
              onChange={(e) => {
                handleChangePolicy({
                  target: { name: "maritalStatus", value: e },
                });
              }}
            />
            {errors?.["maritalStatus"] && (
              <span className="text-danger errors">
                {errors?.["maritalStatus"] && errors?.["maritalStatus"][0]}
              </span>
            )}
          </div>
          <div className="form-group mt-3">
            <label className="form-label">Nationality*</label>
            <ReactSelect
              id={`nationality`}
              placeholder="Nationality"
              styles={customStyles}
              value={
                inputs?.nationality?.value
                  ? inputs?.nationality?.value
                  : { label: inputs?.nationality, value: inputs?.nationality }
              }
              options={countries}
              onChange={(e) => {
                handleChangePolicy({
                  target: { name: "nationality", value: e.value },
                });
              }}
            />
            {errors?.["nationality"] && (
              <span className="text-danger errors">
                {errors?.["nationality"] && errors?.["nationality"][0]}
              </span>
            )}
          </div>
          <NumberInput
            id={"idNumber"}
            label={"ID number"}
            name={"idNumber"}
            value={inputs?.idNumber}
            errors={errors?.["idNumber"]}
            onChange={(e) => {
              if(e?.target.value?.length <=15){
                handleChangePolicy(e)
              }
            }}
            required={false}
          />
          <NumberInput
            id={"bvnNumber"}
            label={"BVN number"}
            name={"bvnNumber"}
            value={inputs?.bvnNumber}
            errors={errors?.["bvnNumber"]}
            onChange={(e) => {
              if(e.target.value?.length <= 11){
                handleChangePolicy(e)
              }
            }}
            required={false}
          />

          <TextInput
            id={"religion"}
            label={"Religion"}
            name={"religion"}
            value={inputs?.religion}
            errors={errors?.["religion"]}
            onChange={(e) => {
              if (e?.target?.value?.length <= 30) {
                handleChangePolicy(e);
              }
            }}
            required={false}
          />

          <div className="row">
            {width > 1024 ? (
              <>
                <div className="col-4">
                  <CountryInput
                    id={"mobilePhoneCode"}
                    label={"Country code"}
                    name={"mobilePhoneCode"}
                    errors={errors?.["mobilePhoneCode"]}
                    placeholder={"+234"}
                    onChange={(e) => handleChangePolicy({target: {name: 'mobilePhoneCode', value: e}}, "mobilePhoneCode")}
                    required={true}
                    country={true}
                  />
                </div>
                <div className="col-8">
                  <NumberInput
                    id={"mobilePhone"}
                    label={"Mobile number"}
                    name={"mobilePhone"}
                    type={"number"}
                    errors={errors?.["mobilePhone"]}
                    value={Number(inputs?.mobilePhone) === 0 ||
                      inputs?.mobilePhone === undefined ||
                      inputs?.mobilePhone === null
                        ? ""
                        : Number(inputs?.mobilePhone).toString()
                    }
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 14) {
                        handleChangePolicy(e);
                      }
                    }}
                    required={true}
                    mobile={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-12">
                  <CountryInput
                    id={"mobilePhoneCode"}
                    label={"Country code"}
                    name={"mobilePhoneCode"}
                    errors={errors?.["mobilePhoneCode"]}
                    value={inputs?.mobilePhoneCode}
                    placeholder={"+234"}
                    onChange={(e) => handleChangePolicy({target: {name: 'mobilePhoneCode', value: e}}, "mobilePhoneCode")}
                    required={true}
                    country={true}
                  />
                </div>
                <div className="col-12">
                  <NumberInput
                    id={"mobilePhone"}
                    label={"Mobile number"}
                    name={"mobilePhone"}
                    type={"number"}
                    errors={errors?.["mobilePhone"]}
                    value={Number(inputs?.mobilePhone) === 0 ||
                      inputs?.mobilePhone === undefined ||
                      inputs?.mobilePhone === null
                        ? ""
                        : Number(inputs?.mobilePhone).toString()
                    }
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 14) {
                        handleChangePolicy(e);
                      }
                    }}
                    required={true}
                    mobile={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row">
            {width > 1024 ? (
              <>
                <div className="col-4">
                  <CountryInput
                    id={"landlinePhoneCode"}
                    label={"Country code"}
                    name={"landlinePhoneCode"}
                    errors={errors?.["landlinePhoneCode"]}
                    value={inputs?.landlinePhoneCode}
                    placeholder={"+234"}
                    onChange={(e) => handleChangePolicy({target: {name: 'landlinePhoneCode', value: e}}, "landlinePhoneCode")}
                    required={false}
                    country={true}
                  />
                </div>
                <div className="col-8">
                  <NumberInput
                    id={"landlinePhone"}
                    label={"Telephone number"}
                    name={"landlinePhone"}
                    type="number"
                    errors={errors?.["landlinePhone"]}
                    value={inputs?.landlinePhone}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        if (e?.target?.value?.length <= 14) {
                          handleChangePolicy(e);
                        }
                      }
                    }}
                    required={false}
                    mobile={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-12">
                  <CountryInput
                    id={"landlinePhoneCode"}
                    label={"Country code"}
                    name={"landlinePhoneCode"}
                    errors={errors?.["landlinePhoneCode"]}
                    value={inputs?.landlinePhoneCode}
                    placeholder={"+234"}
                    onChange={(e) => handleChangePolicy({target: {name: 'landlinePhoneCode', value: e}}, "landlinePhoneCode")}
                    required={false}
                    country={true}
                  />
                </div>
                <div className="col-12">
                  <NumberInput
                    id={"landlinePhone"}
                    label={"Telephone number"}
                    name={"landlinePhone"}
                    type="number"
                    errors={errors?.["landlinePhone"]}
                    value={inputs?.landlinePhone}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        if (e?.target?.value?.length <= 14) {
                          handleChangePolicy(e);
                        }
                      }
                    }}
                    required={false}
                    mobile={true}
                  />
                </div>
              </>
            )}
          </div>

          <TextInput
            id={"email"}
            label={"Email address"}
            name={"email"}
            errors={errors?.["email"]}
            value={inputs?.email}
            onChange={(e) => handleChangePolicy(e)}
            required={true}
          />
          <div className="form-group mt-3">
            <label className="primary-color f-700">Physical address</label>
          </div>
          <TextInput
            id={"physicalAddress"}
            label={"Address 1"}
            errors={errors?.["physicalAddress"]}
            name={"physicalAddress"}
            value={inputs?.physicalAddress}
            onChange={(e) => handleChangePolicy(e)}
            required={true}
          />
          <TextInput
            id={"address2"}
            label={"Address 2"}
            errors={errors?.["address2"]}
            name={"address2"}
            value={inputs?.address2}
            onChange={(e) => handleChangePolicy(e)}
            required={false}
          />
          <TextInput
            id={"city"}
            label={"City"}
            errors={errors?.["city"]}
            name={"city"}
            value={inputs?.city}
            onChange={(e) => handleChangePolicy(e)}
            required={true}
          />

            <div className="form-group mt-3">
                  <label className="form-label">State*</label>
                  <ReactSelect
                    id={`region`}
                    placeholder="Select state"
                    value={inputs?.region}
                    styles={customStyles}
                    options={states}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "region", value: e },
                      });
                    }}
                  />
                  {errors?.["region"] && (
                    <span className="text-danger errors">
                      {errors?.["region"] &&
                        errors?.["region"][0]}
                    </span>
                  )}
                </div>


          <div className="form-group mt-3">
            <label className="primary-color f-700">Postal address</label>
          </div>
          <TextInput
            id={"postalAddress"}
            label={"Address 1"}
            errors={errors?.["postalAddress"]}
            name={"postalAddress"}
            placeholder="If not the same as physical address"
            value={inputs?.postalAddress}
            onChange={(e) => handleChangePolicy(e)}
            required={false}
          />
          <TextInput
            id={"postalCity"}
            label={"City"}
            errors={errors?.["postalCity"]}
            name={"postalCity"}
            value={inputs?.postalCity}
            onChange={(e) => handleChangePolicy(e)}
            required={false}
          />
          <div className="form-group mt-3">
                  <label className="form-label">State</label>
                  <ReactSelect
                    id={`postalProvince`}
                    placeholder="Select state"
                    value={inputs?.postalProvince}
                    styles={customStyles}
                    options={states}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "postalProvince", value: e },
                      });
                    }}
                  />
                  {errors?.["postalProvince"] && (
                    <span className="text-danger errors">
                      {errors?.["postalProvince"] &&
                        errors?.["postalProvince"][0]}
                    </span>
                  )}
                </div>

          <div style={{height: '10px'}} />
          <TwoButtons
            id={"politicallyExposed"}
            title={`${riskAtrributes?.find((x) => x.code === 'MAM')?.attributes?.find((y) => y.name === 'Politically Exposed Individual')?.description}*`}
            isField={true}
            half={true}
            options={[ {name: 'Yes', value: 'YES'}, { name: 'No', value: 'NO' } ]}
            errors={errors?.["politicallyExposed"]}
            name={"politicallyExposed"}
            state={riskAtrributes?.find((x) => x.code === 'MAM')?.attributes?.find((y) => y.name === 'Politically Exposed Individual')?.value}
            onClick={(e) => {
              var attribute = riskAtrributes.find((x) => x.code === 'MAM')?.attributes;
              var arr = [...attribute];
              var index = attribute.findIndex((x) => x.instanceId === riskAtrributes?.find((x) => x.code === 'MAM')?.attributes?.find((y) => y.name === 'Politically Exposed Individual')?.instanceId);
              arr[index] = { ...arr[index], value: e };
              dispatch(updatedAttributes(arr, 'MAM'));
            }}
            required={false}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PolicyMember;
