
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactSelect from "react-select";
import {
  CountryInput,
  DateInput,
  FileUpload,
  NumberInput,
  TextInput,
  TwoButtons,
} from "../../../../components";
const moment = require('moment');

const Beneficiaries = ({
    beneficiaries,
    handleChangeBene,
    errors,
    getRows,
    customStyles,
    setBeneficiaries,
    beneCount,
    setBeneCount,
    range,
    percentageArr,
    setIsTyped,
    isTyped,
    width,
    calculateGuardianMaxDate,
    calculateBeneMinDate,
    calculateBeneMaxDate,
    calculateage
}) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    setStates([		
            {label: "Abia", value: "Abia",},
            {label: "Adamawa", value: "Adamawa",},
            {label: "Akwa Ibom",value: "Akwa Ibom",},
            {label: "Anambra",value: "Anambra",},
            {label: "Bauchi", value: "Bauchi",},
            {label: "Bayelsa", value: "Bayelsa",},
            {label: "Benue",value: "Benue",},
            {label: "Borno",value: "Borno",},
            {label: "Cross River", value: "Cross River",},
            {label: "Delta", value: "Delta",},
            {label: "Ebonyi",value: "Ebonyi",},
            {label: "Edo",value: "Edo",},
            {label: "Ekiti", value: "Ekiti",},
            {label: "Enugu", value: "Enugu",},
            {label: "Fct",value: "Fct",},
            {label: "Gombe",value: "Gombe",},
            {label: "Imo", value: "Imo",},
            {label: "Jigawa", value: "Jigawa",},
            {label: "Kaduna",value: "Kaduna",},
            {label: "Kano",value: "Kano",},
            {label: "Katsina", value: "Katsina",},
            {label: "Kebbi", value: "Kebbi",},
            {label: "Kogi",value: "Kogi",},
            {label: "Kwara",value: "Kwara",},
            {label: "Lagos", value: "Lagos",},
            {label: "Nasarawa", value: "Nasarawa",},
            {label: "Niger",value: "Niger",},
            {label: "Ogun",value: "Ogun",},
            {label: "Ondo", value: "Ondo",},
            {label: "Osun", value: "Osun",},
            {label: "Others",value: "Others",},
            {label: "Oyo",value: "Oyo",},
            {label: "Plateau", value: "Plateau",},
            {label: "Rivers", value: "Rivers",},
            {label: "Sokoto",value: "Sokoto",},
            {label: "Taraba",value: "Taraba",},
            {label: "Yobe", value: "Yobe",},
            {label: "Zamfara", value: "Zamfara",},

   ])
  },[])

  const calculateTrusteeMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 100);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };
  const calculateTrusteeMaxDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };
    return(
        <>
        <Accordion
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    4
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add beneficiaries details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {getRows(beneCount)?.map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Beneficiary #{i + 1}</p>
                  <TextInput
                    id={`First Name ${i + 1}`}
                    label={"First name"}
                    name={"firstName"}
                    placeholder={""}
                    value={beneficiaries[i]?.["firstName"]}
                    errors={errors?.[`Beneficiary First Name ${i + 1}`]}
                    onChange={(e) => {
                      if(e?.target?.value?.length <=30){
                        handleChangeBene(e, i)
                      }
                    }}
                    required={true}
                  />
                  <TextInput
                    id={`Last Name ${i + 1}`}
                    label={"Last name"}
                    name={"lastName"}
                    placeholder={""}
                    value={beneficiaries[i]?.["lastName"]}
                    errors={errors[`Beneficiary Last Name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                 
                  <div style={{ marginTop: 20 }} />
                  <TwoButtons
                    id={`Gender ${i + 1}`}
                    title={"Gender"}
                    isField={true}
                    half={true}
                    required={true}
                    errors={errors?.[`Gender ${i + 1}`]}
                    state={beneficiaries[i]?.["gender"]}
                    onClick={(e) =>
                      handleChangeBene(
                        { target: { name: "gender", value: e } },
                        i
                      )
                    }
                    options={[
                      {
                        name: "Male",
                        value: "Male",
                      },
                      {
                        name: "Female",
                        value: "Female",
                      },
                    ]}
                  />
                  <div className="row">
                   {width > 1024 ? <>
                    <div className="col-4">
                      <CountryInput
                        id={`Phone Number Code ${i + 1}`}
                        label={"Country code*"}
                        name={"Phone Number Code"}
                        errors={errors?.[`Phone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Phone Number Code"]}
                        placeholder={"+267"}
                        onChange={(e) =>
                          handleChangeBene(
                            { target: { name: "Phone Number Code", value: e } },
                            i
                          )
                        }
                        required={false}
                        country={true}
                      />
                    </div>
                    <div className="col-8">
                      <NumberInput
                        id={`Phone Number ${i + 1}`}
                        label={"Mobile number"}
                        name={"number"}
                        type={"number"}
                        errors={errors[`Phone Number ${i + 1}`]}
                        value={beneficiaries[i]?.["number"]}
                        onChange={(e) => {
                          if(e?.target?.value?.length <= 12){
                            handleChangeBene(e, i)
                          }
                        }}
                        required={true}
                        mobile={true}
                      />
                    </div>
                   </> : <>
                   <div className="col-12">
                      <CountryInput
                        id={`Phone Number Code ${i + 1}`}
                        label={"Country code*"}
                        name={"Phone Number Code"}
                        errors={errors?.[`Phone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Phone Number Code"]}
                        placeholder={"+267"}
                        onChange={(e) =>
                          handleChangeBene(
                            { target: { name: "Phone Number Code", value: e } },
                            i
                          )
                        }
                        required={false}
                        country={true}
                      />
                    </div>
                    <div className="col-12">
                      <NumberInput
                        id={`Phone Number ${i + 1}`}
                        label={"Mobile number"}
                        name={"number"}
                        type={"number"}
                        errors={errors[`Phone Number ${i + 1}`]}
                        value={beneficiaries[i]?.["number"]}
                        onChange={(e) => {
                          if(e?.target?.value?.length <= 12){
                            handleChangeBene(e, i)
                          }
                        }}
                        required={true}
                        mobile={true}
                      />
                    </div>
                   </>}
                  </div>
                  <div className="row">
                    {width > 1024 ? <>
                      <div className="col-4">
                      <CountryInput
                        id={`Telephone Number Code ${i + 1}`}
                        label={"Country code"}
                        name={"Telephone Number Code"}
                        errors={errors?.[`Telephone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Telephone Number Code"]}
                        placeholder={"+267"}
                        onChange={(e) =>
                          handleChangeBene(
                            {
                              target: {
                                name: "Telephone Number Code",
                                value: e,
                              },
                            },
                            i
                          )
                        }
                        required={false}
                        country={true}
                      />
                    </div>
                    <div className="col-8">
                      <NumberInput
                        id={`Telephone Number ${i + 1}`}
                        label={"Telephone number"}
                        type="number"
                        name={"telephoneNumber"}
                        value={beneficiaries[i]?.["telephoneNumber"]}
                        errors={errors[`Telephone number ${i + 1}`]}
                        onChange={(e) => {
                          if(e?.target?.value?.length <= 12){
                            handleChangeBene(e, i)
                          }
                        }}
                        required={false}
                        mobile={true}
                      />
                    </div>
                    </> : 
                    <>
                    <div className="col-12">
                      <CountryInput
                        id={`Telephone Number Code ${i + 1}`}
                        label={"Country code"}
                        name={"Telephone Number Code"}
                        errors={errors?.[`Telephone Number Code ${i + 1}`]}
                        value={beneficiaries[i]?.["Telephone Number Code"]}
                        placeholder={"+267"}
                        onChange={(e) =>
                          handleChangeBene(
                            {
                              target: {
                                name: "Telephone Number Code",
                                value: e,
                              },
                            },
                            i
                          )
                        }
                        required={false}
                        country={true}
                      />
                    </div>
                    <div className="col-12">
                      <NumberInput
                        id={`Telephone Number ${i + 1}`}
                        label={"Telephone number"}
                        name={"telephoneNumber"}
                        type="number"
                        value={beneficiaries[i]?.["telephoneNumber"]}
                        errors={errors[`Telephone number ${i + 1}`]}
                        onChange={(e) => {
                          if(e?.target?.value?.length <= 12){
                            handleChangeBene(e, i)
                          }
                        }}
                        required={false}
                        mobile={true}
                      />
                    </div>
                    </>}
                  </div>

                  <TextInput
                    id={`Email ${i + 1}`}
                    label={"Email address"}
                    name={"email"}
                    value={beneficiaries[i]?.["email"]}
                    errors={errors[`Email ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={false}
                  />
                  <p className="primary-color f-700 mt-4">Address</p>
                  <TextInput
                    id={`address1 ${i + 1}`}
                    label={"Address 1"}
                    name={"address1"}
                    value={beneficiaries[i]?.["address1"]}
                    errors={errors[`Address 1 ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    placeholder={"Address 1"}
                    required={true}
                  />
                  <TextInput
                    id={`address2 ${i + 1}`}
                    label={"Address 2"}
                    name={"address2"}
                    value={beneficiaries[i]?.["address2"]}
                    errors={errors[`Address 2 ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    placeholder={"Address 2"}
                    required={false}
                  />
                 <TextInput
                    id={`city ${i + 1}`}
                    label={"City"}
                    name={"city"}
                    value={beneficiaries[i]?.["city"]}
                    errors={errors[`city ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    placeholder={"City"}
                    required={true}
                  />
                <div className="form-group mt-3">
                  <label className="form-label">State*</label>
                  <ReactSelect
                    id={`state ${i + 1}`}
                    placeholder="Select state"
                    value={states?.find((x) => x.value === beneficiaries[i]?.["state"])}
                    styles={customStyles}
                    options={states}
                    onChange={(e) => {
                      handleChangeBene({
                        target: { name: "state", value: e },
                      },i);
                    }}
                  />
                  {errors?.[`state ${i + 1}`] && (
                    <span className="text-danger errors">
                      {errors?.[`state ${i + 1}`] &&
                        errors?.[`state ${i + 1}`][0]}
                    </span>
                  )}
                </div>
                  <TextInput
                    id={`occupation ${i + 1}`}
                    label={"Occupation"}
                    name={"occupation"}
                    value={beneficiaries[i]?.["occupation"]}
                    errors={errors[`Occupation ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    placeholder={"Occupation"}
                    required={false}
                  />
                   <DateInput
                    id={`dob ${i + 1}`}
                    label={"Date of birth"}
                    name={"dob"}
                    errors={errors[`Beneficiary Date of Birth ${i + 1}`]}
                    maxDate={calculateBeneMaxDate()}
                    value={beneficiaries[i]?.["dob"] ?? ""}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                    placeholder={"dd-mm-yyyy"}
                  />
                  <div className="form-group mt-3">
                    <label className="form-label">Relationship with policy holder</label>
                    <ReactSelect 
                      styles={customStyles}
                      options={[
                        {
                          label: 'Self',
                          value: 'Self'
                        },
                        {
                          label: 'Other',
                          value: 'Other'
                        },
                        {
                          label: 'Spouse',
                          value: 'Spouse'
                        },
                        {
                          label: 'Son',
                          value: 'Son'
                        },
                        {
                          label: 'Daughter',
                          value: 'Daughter'
                        },
                        {
                          label: 'Father',
                          value: 'Father'
                        },
                        {
                          label: 'Mother',
                          value: 'Mother'
                        },
                        {
                          label: 'Brother',
                          value: 'Brother'
                        },
                        {
                          label: 'Sister',
                          value: 'Sister'
                        },
                        {
                          label: 'Father-in-law',
                          value: 'Father-in-law'
                        },
                        {
                          label: 'Mother-in-law',
                          value: 'Mother-in-law'
                        },
                        {
                          label: 'Uncle',
                          value: 'Uncle'
                        },
                        {
                          label: 'Aunt',
                          value: 'Aunt'
                        },
                        {
                          label: 'Grandfather',
                          value: 'Grandfather'
                        },
                        {
                          label: 'Grandmother',
                          value: 'Grandmother'
                        },
                        {
                          label: 'Brother-in-law',
                          value: 'Brother-in-law'
                        },
                        {
                          label: 'Sister-in-law',
                          value: 'Sister-in-law'
                        },
                        {
                          label: 'Nephew',
                          value: 'Nephew'
                        },
                        {
                          label: 'Niece',
                          value: 'Niece'
                        },
                      ]}
                      value={beneficiaries[i]?.["relationship"] ? { label: beneficiaries[i]?.["relationship"], value: beneficiaries[i]?.["relationship"]} : {}}
                      onChange={(e) => handleChangeBene({target: { name: 'relationship', value: e.value } }, i)}
                    />
                  </div>
                 
                  <div className="form-group mt-3">
                    <label className="form-label">Percentage of benefit*</label>
                    <ReactSelect
                      id={`Percentage ${i + 1}`}
                      placeholder="0"
                      styles={customStyles}
                      defaultValue={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      value={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      options={
                        isTyped
                          ? range(0, 100).map((x, i) => ({
                            label: `${x}%`,
                            value: x,
                          }))
                          : percentageArr.map((x) => ({
                            label: `${x.name}`,
                            value: x.value,
                          }))
                      }
                      onInputChange={(e) => {
                        if (e > 0) {
                          setIsTyped(true);
                        } else {
                          setIsTyped(false);
                        }
                      }}
                      onChange={(e) => {
                        handleChangeBene(
                          { target: { name: "percentage", value: e.value } },
                          i
                        );
                      }}
                      
                      isSearchable={true}
                    />
                  </div>
                  {(
                    errors[`Percentage ${i + 1}`] ||
                    errors[`beneficiariesSplit`]
                  )?.length && (
                      <span
                        className="text-danger errors"
                        style={{ marginLeft: 0 }}
                      >
                        {errors[`Percentage ${i + 1}`] ||
                          (errors[`beneficiariesSplit`] &&
                            errors[`Percentage ${i + 1}`]) ||
                          errors[`beneficiariesSplit`][0]}
                      </span>
                    )}
                     {beneficiaries[i]?.["dob"] &&
                    calculateage(beneficiaries[i]?.["dob"], i) && (
                      <div
                        style={{
                          border: "1px solid #00000030",
                          marginTop: 20,
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        <p className="primary-color f-700">Guardian</p>
                        <TextInput
                          id={`Guardian First Name ${i + 1}`}
                          label={"First name"}
                          name={"guardianName"}
                          value={beneficiaries[i]?.["guardianName"]}
                          errors={errors[`Guardian First Name ${i + 1}`]}
                          onChange={(e) => {
                            if(e?.target?.value?.length <=30){
                              handleChangeBene(e, i)
                            }
                          }}
                          required={true}
                        />
                        <TextInput
                          id={`Guardian Last Name ${i + 1}`}
                          label={"Last name"}
                          errors={errors[`Guardian Last Name ${i + 1}`]}
                          name={"guardianLastName"}
                          value={beneficiaries[i]?.["guardianLastName"]}
                          onChange={(e) =>{
                            if(e?.target?.value?.length <=30){
                              handleChangeBene(e, i)
                            }
                          }}
                          required={true}
                        />
                        <div className="row">
                         {width > 1024 ? <>
                          <div className="col-4">
                            <CountryInput
                              id={`Guardian Phone Number Code ${i + 1}`}
                              label={"Country code*"}
                              width={"110%"}
                              name={"Guardian Phone Number Code"}
                              errors={
                                errors?.[`Guardian Phone Number Code ${i + 1}`]
                              }
                              value={
                                beneficiaries[i]?.["Guardian Phone Number Code"]
                              }
                              placeholder={"+267"}
                              onChange={(e) =>
                                handleChangeBene(
                                  {
                                    target: {
                                      name: "Guardian Phone Number Code",
                                      value: e,
                                    },
                                  },
                                  i
                                )
                              }
                              required={false}
                              country={true}
                            />
                          </div>
                          <div className="col-8">
                            <NumberInput
                              id={`Guardian Phone Number ${i + 1}`}
                              label={"Mobile number"}
                              name={"guardianNumber"}
                              type={'number'}
                              value={beneficiaries[i]?.["guardianNumber"]}
                              errors={errors[`Guardian Phone Number ${i + 1}`]}
                              onChange={(e) => {
                                if(e?.target?.value?.length <=12){
                                  handleChangeBene(e, i)
                                }
                              }}
                              required={true}
                              mobile={true}
                            />
                          </div>
                         </> : 
                         <>
                            <div className="col-11">
                            <CountryInput
                              id={`Guardian Phone Number Code ${i + 1}`}
                              label={"Country code*"}
                              width={"100%"}
                              name={"Guardian Phone Number Code"}
                              errors={
                                errors?.[`Guardian Phone Number Code ${i + 1}`]
                              }
                              value={
                                beneficiaries[i]?.["Guardian Phone Number Code"]
                              }
                              placeholder={"+267"}
                              onChange={(e) =>
                                handleChangeBene(
                                  {
                                    target: {
                                      name: "Guardian Phone Number Code",
                                      value: e,
                                    },
                                  },
                                  i
                                )
                              }
                              required={false}
                              country={true}
                            />
                          </div>
                          <div className="col-12">
                            <NumberInput
                              id={`Guardian Phone Number ${i + 1}`}
                              label={"Mobile number"}
                              name={"guardianNumber"}
                              type={'number'}
                              placeholder={"12 345 678 "}
                              value={beneficiaries[i]?.["guardianNumber"]}
                              errors={errors[`Guardian Phone Number ${i + 1}`]}
                              onChange={(e) => {
                                if(e?.target?.value?.length <=12){
                                  handleChangeBene(e, i)
                                }
                              }}
                              required={true}
                              mobile={true}
                            />
                          </div>
                         </>}
                        </div>
                        <DateInput
                          id={`Guardian dob ${i + 1}`}
                          label={"Date of birth"}
                          name={"guardianDob"}
                          minDate={calculateTrusteeMinDate()}
                          errors={errors[`Guardian dob ${i + 1}`]}
                          maxDate={calculateTrusteeMaxDate()}
                          value={beneficiaries[i]?.["guardianDob"] ?? ""}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                          placeholder={"dd-mm-yyyy"}
                        />
                      </div>
                    )}
                
                  <div className="d-flex justify-content-end w-100 mt-4">
                    {beneCount > 1 && (
                      <button
                        className="delete-button"
                        onClick={() => {
                          if (beneCount > 1) {
                            var arr = [...beneficiaries];
                            arr.splice(i, 1);
                            setBeneficiaries(arr);
                            setBeneCount(beneCount - 1);
                          }
                        }}
                      >
                        Delete
                      </button>
                    )}
                    {beneCount < 10 && getRows(beneCount)?.length - 1 === i && (
                      <button
                        className="add-button"
                        onClick={() => {
                          var arr = [...beneficiaries];
                          arr.push({
                            "Phone Number Code": "+267",
                            "Telephone Number Code": "+267"
                          });
                          setBeneficiaries(arr);
                          setBeneCount(beneCount + 1)
                        }}
                      >
                        Add Beneficiaries
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </>
    )
}

export default Beneficiaries;