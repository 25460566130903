import React, { useCallback, useEffect, useState } from "react";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import Loader from "../../../components/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import {
  CountryInput,
  DateInput,
  FileUpload,
  Info,
  NumberInput,
  Question,
  TextInput,
  TwoButtons,
} from "../../../components";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import PolicyMember from "./components/PolicyMember";
import {
  CreatePolicy,
  policySaleAttachments,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import Beneficiaries from "./components/Beneficiaries";
import { v4 as uuidv4 } from "uuid";
import localforage from "localforage";
import { validate } from "validate.js";
import { beneficiariesSchema, mainMemberSchema, medicalQuestions, proofOfIdentitySchema } from "./components/constraints";
import { trackUserEvents } from "../../../utils/utils";

const moment = require("moment");

const Step2 = ({ handleNext, handleBack, handleFileUpload, files }) => {
  const auth = useSelector((state) => state?.auth?.refererData?.customer);
  const [isLoading, setIsLoading] = useState(false);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const beneState = useSelector((state) => state?.products?.beneficiaries);
  const customAttributes = useSelector((state) => state?.products?.customAttribues);
  const [errors, setErrors] = useState({});
  const [memberHooks, setMemberHooks] = useState({...mainMember});
  const [authHooks, setAuthHooks] = useState({...auth});
  const [inputs, setInputs] = useState({ 
    ...memberHooks,
    mobilePhoneCode: "+234" ,
    firstName : (memberHooks && memberHooks?.firstName) ?? authHooks?.firstName ,
    lastName :(memberHooks &&  memberHooks?.lastName) ?? authHooks?.lastName,
    eMail: (memberHooks && memberHooks?.email) ?? authHooks?.eMail ,
    mobilePhone: (memberHooks && memberHooks?.mobilePhone) ?? authHooks?.mobilePhone ,
    address: (memberHooks && memberHooks?.address) ?? authHooks?.address,
    address2: (memberHooks && memberHooks?.address2) ??  authHooks?.address2 ,
    birthDate: (memberHooks && moment(memberHooks?.dob).format("DD-MM-YYYY")) ?? moment(authHooks?.birthDate).format("DD-MM-YYYY")  
  });
  const { width } = useWindowDimensions();
  const calculatorResponse = useSelector((state) => state?.products?.executeCalculator);
  const dispatch = useDispatch();
  const factors = useSelector((state) => state?.products?.factors)
  const history = useNavigate();
  const reducerState = useSelector((state) => state?.products);
  const riskAttributes = useSelector((state) =>
    state?.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes?.map((y) => ({ ...y, code: x.code })),
    }))
  );
  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);
  const attOpts = useSelector(
    (state) => state.products?.policyAttachmentsOptions
  );
  const attachments = useSelector((state) => state?.products?.attachments);


  const handleChangePolicy = (e) => {
    var obj = {
      ...inputs,
      [e.target.name]: e.target.value,
    };
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
  };

  const calculateMinDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 60);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      // Spouse -> undefined
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const handleChange = (evt, instanceId, name,code, type) => {
    if (type === "medical") {
      var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
      var arr = [...attribute];
      var index = attribute.findIndex((x) => x.instanceId === instanceId);
      arr[index] = { ...arr[index], value: evt };
      
      if(evt === "NO"){
        var indexNo = attribute.findIndex((x) => x.name.toLowerCase() === `if yes ${name.toLowerCase()}`)
        arr[indexNo] = { ...arr[indexNo], value: "" };
      }
      dispatch(updatedAttributes(arr, code));
    }
    if (type === "doctor") {
      if(instanceId === null) return;
      var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
      var arr = [...attribute];
      var index = attribute.findIndex((x) => x.instanceId === instanceId);
      arr[index] = { ...arr[index], value: typeof evt === 'object' ? evt.target.value : evt};
      dispatch(updatedAttributes(arr, code));
    }
  };

  const getExtraErrors = (instanceId) => {
    var attributes = riskAttributes?.find((x) => x.code === 'ADD')?.attributes;
    var index = attributes?.findIndex((y) => y.instanceId === instanceId);
    return errors[attributes[index+1]?.name]
  }

  const renderQuestions = useCallback(
    (item, custom) => {
      switch (item.dataType) {
        case 7:
          return (
            <Question
              id={item?.name}
              label={item.description}
              half={true}
              required={item?.isRequired}
              options={JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              onClick={(e) =>{
                handleChange(e, item?.instanceId, item?.name, item?.code, custom)
              }
              }
              errors={errors[item.name]}
              extraErrors={getExtraErrors(item?.instanceId)}
              state={item?.value}
              extras={
                item?.value === "YES" 
                  ? riskAttributes
                      ?.find((x) => x.code === "ADD")
                      ?.attributes?.find((y) =>
                        y.name
                          .toLowerCase()
                          .includes(`if yes ${item.name.toLowerCase()}`)
                      )
                  : null
              }
              handleExtraChange={(e) =>{
                handleChange(
                  e.target.value,
                  riskAttributes
                    ?.find((x) => x.code === "ADD")
                    ?.attributes?.find((y) =>
                      y.name
                        .toLowerCase()
                        .includes(`if yes ${item.name.toLowerCase()}`)
                    )?.instanceId,
                    item?.name,
                  item?.code,
                  custom
                )
              }
              }
            />
          );
        case 1:
          return (
            <TextInput
              label={item.description}
              id={item?.name}
              half={true}
              options={JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              required={item.isRequired}
              errors={errors[item.name]}
              onChange={(e) =>
                {
                  if(custom === 'doctor'){
                    if(e.target.value?.length <= 30){
                      handleChange(e, item?.instanceId, item?.name, item?.code, custom)
                    }
                  }else{
                    handleChange(e, item?.instanceId, item?.name, item?.code, custom)
                  }
                }
              }
              value={item?.value}
            />
          );
          case 2:
            return (
              item?.name?.toLowerCase()?.includes('mobile') ? (
                <div className="row">
                  {width > 1024 ? <>
                    <div className="col-4">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={custom === 'doctor' ? errors?.doctorPhoneCode : []}
                      value={"+234"}
                      placeholder={"+234"}
                      onChange={(e) =>  {
                        if(custom === 'doctor'){
                          dispatch(updateCustomAttributes({
                            type: 'CUSTOM_ATTRIBUTES',
                            payload: {
                              ...customAttributes,
                              doctorPhoneCode: e
                            }
                          }))
                        }else{
                          handleChange(e, null, 'mobilePhoneCode', item.code, custom)
                        }
                      }}
                      required={false}
                      country={true}
                    />
                  </div>
                  <div className="col-8">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      name={item.name}
                      errors={custom === 'doctor' ? errors?.[item.name] : errors?.["mobilePhone"]}
                      type="number"
                      value={
                         item?.value
                      }
                      onChange={(e) => {
                        if(e?.target?.value?.length <=12){
                         handleChange(e.target.value, item.instanceId,item?.name, item.code, custom)
                        }
                      }}
                      required={item.isRequired}
                      mobile={true}
                    />
                  </div>
                  </> : 
                  <>
                  <div className="col-12">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={custom === 'doctor' ? errors?.doctorPhoneCode : []}
                      value={"+234"}
                      placeholder={"+234"}
                      onChange={(e) =>  {
                        if(custom === 'doctor'){
                          dispatch(updateCustomAttributes({
                            type: 'CUSTOM_ATTRIBUTES',
                            payload: {
                              ...customAttributes,
                              doctorPhoneCode: e
                            }
                          }))
                        }else{
                          handleChange(e, null, 'mobilePhoneCode', item.code, custom)
                        }
                      }}
                      required={false}
                      country={true}
                    />
                  </div>
                  <div className="col-12">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      name={item.name}
                      errors={custom === 'doctor' ? errors?.[item.name] : errors?.["mobilePhone"]}
                      type="number"
                      value={
                         item?.value
                      }
                      onChange={(e) => {
                        if(e?.target?.value?.length <=12){
                          handleChange(e.target.value, item.instanceId, item?.name, item.code, custom)
                        }
                      }}
                      required={item.isRequired}
                      mobile={true}
                    />
                  </div>
                  </>}
                </div>
              )
                :
                <NumberInput
                  id={item.name}
                  label={item.description}
                  errors={ errors[item.name]}
                  name={item.name}
                  placeholder={item.description}
                  type="number"
                  value={
                    item?.value
                  }
                  onChange={(e) =>
                    {
                      if(item?.name === 'Height' || item?.name === 'Weight'){
                        if(e.target.value?.length <=3){
                          handleChange(e.target.value, item.instanceId, item?.name, item.code, custom)
                        }
                      }else{
                        handleChange(e.target.value, item.instanceId,item?.name, item.code, custom)
                      }
                    }
                  }
                  required={item.isRequired}
                />
            );
      }
    },
    [riskAttributes, errors]
  );

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: new Date(),
      postalAddress: "",
      gender: "",
      country: "",
      telephoneNumber: "",
      idNumber: "",
      "Telephone Number Code": "+234",
      "Phone Number Code": "+234",
      "Guardian Phone Number Code": "+234",
    },
  ]);

  useEffect(() => {
    if (beneState?.length) {
      setBeneficiaries(beneState);
    }
  }, [beneState]);

  const [beneCount, setBeneCount] = useState(1);

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const calculateBeneMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 36);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMaxDate = () => {
    const date = new Date();
    return new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [isTyped, setIsTyped] = useState(false);

  const handleChangeBene = (evt, index) => {
    var arr = [...beneficiaries];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.name === "state" ? evt.target.value.value : evt.target.value };
    if (evt.target.name === 'dob') {
      arr[index] = { ...arr[index], "Guardian Phone Number Code": arr[index]?.["Guardian Phone Number Code"] ?? "+234" }
      setBeneficiaries(arr);
      return dispatch(
        updateCustomAttributes({
          type: 'BENEFICIARIES',
          payload: arr
        })
      )
    }
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const doctorKeys = [
    "Doctors name",
    "Name of Hospital",
    "Hospital Address",
    "Doctors Mobile",
    "Patient",
  ];

  const calculateage = (date, i) => {
    var diffInYears = moment().diff(date, "years");
    if (diffInYears < 18) {
      return true;
    }
  };

  const calculateTrusteeMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

 
  useEffect(() => {
    var dateOfBirth = customAttributes.dateOfBirth;
    setInputs({
      ...inputs,
      dob: inputs?.dob ?? moment(dateOfBirth).format('DD-MM-YYYY')
    })
  },[])

  const handleSubmit = async () => {
    try {
      var values = {
        ...inputs
      };
      values = {
        ...values,
        politicallyExposed: riskAttributes?.find((x) => x.code === 'MAM')?.attributes?.find((y) => y.name === 'Politically Exposed Individual')?.value,
        doctorPhoneCode: customAttributes?.doctorPhoneCode,
        uniqueNumber: customAttributes?.uniqueNumber,
        proofOfAddress: attachments?.address,
        proofOfIdentity: attachments?.personal

      }

      let beneAttributes = [];

      if (beneState?.length) {
        getRows(beneCount).map((x, i) => {
          beneAttributes.push(`Beneficiary First Name ${i + 1}`);
          beneAttributes.push(`Last Name ${i + 1}`);
          beneAttributes.push(`Email ${i + 1}`);
          beneAttributes.push(`Percentage ${i + 1}`);
          values = {
            ...values,
            [`Beneficiary First Name ${i + 1}`]: Object.keys(
              beneState[i] ?? {}
            )?.length
              ? beneState[i]?.["firstName"]
              : null,
            [`Beneficiary Last Name ${i + 1}`]: Object.keys(
              beneState[i] ?? {}
            )?.length
              ? beneState[i]?.["lastName"]
              : null,
            [`Email ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["email"]
              : null,
            [`Phone Number ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["number"]
              : null,
            [`Phone Number Code ${i + 1}`]: Object.keys(beneState[i] ?? {})
              ?.length
              ? beneState[i]["Phone Number Code"]
              : null,
            [`Percentage ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["percentage"]
              : null,
            [`Telephone number ${i + 1}`]: Object.keys(beneState[i] ?? {})
              ?.length
              ? beneState[i]["telephoneNumber"]
              : null,
            [`Beneficiary Date of Birth ${i + 1}`]: Object.keys(
              beneState[i] ?? {}
            )?.length
              ? beneState[i]?.["dob"]
              : null,
            [`Telephone Number Code ${i+1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["Telephone Number Code"]
            : null,
            [`Occupation ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["occupation"]
              : null,
            [`Address 1 ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["address1"]
              : null,
            [`Address 2 ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["address2"]
              : null,
            [`Gender ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["gender"]
              : null,
            [`city ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["city"]
              : null,
              [`state ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["state"]
              : null,
  
            [`Guardian First Name ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["guardianName"]
              : null,
            [`Guardian Last Name ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["guardianLastName"]
              : null,
            [`Guardian dob ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["guardianDob"]
              : null,
            [`Guardian Phone Number ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["guardianNumber"]
              : null,
            [`Guardian Phone Number Code ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
              ? beneState[i]["Guardian Phone Number Code"] ?? "+234"
              : null,
          };
        });
      };

      riskAttributes?.find((x) => x.code === 'ADD')?.attributes?.forEach((y) => {
        values = {
          ...values,
          [y.name]: y.value
        }
      });

      var constraint =  {
        ...mainMemberSchema(inputs),
        ...beneficiariesSchema(beneCount, getRows, calculateage, beneficiaries),
        ...medicalQuestions(riskAttributes),
        ...proofOfIdentitySchema()
      }; 

      if(customAttributes?.doctorPhoneCode){
        constraint = {
          ...constraint,
          doctorPhoneCode: {
            presence: {
              allowEmpty: false
            },
            length: {
              maximum: 4,
              message: "^ Doctor's phone code is too long (max is 4 characters)"
            }
          }
        }
      }
      var error = validate(values, constraint);

      if (error || error !== undefined) {
        if (beneState?.length) {
          Object.keys(error)?.map((x) => {
            if (beneAttributes?.includes(x)) {
              error = {
                ...error,
                beneficiaries: ["Please fill beneficiaries details "],
              };
            }
          });
        }
      }
  
      var mainMemberAttributes = [
        'firstName',
        'lastName',
        'title',
        'occupation',
        'employer',
        'position',
        'business',
        'source',
        'mainGender',
        'maritalStatus',
        'nationality',
        'idNumber',
        'bvnNumber',
        'religion',
        'mobilePhoneCode',
        'mobilePhone',
        'landlinePhoneCode',
        'landlinePhone',
        'email',
        'physicalAddress',
        'address2',
        'city',
        'region',
        'postalAddress',
        'postalCity',
        'postalProvince',
        'politicallyExposed',
      
      ];
      if (error || error !== undefined) {
        if (Object.keys(inputs)?.length) {
          Object.keys(error)?.map((x) => {
            if (mainMemberAttributes?.includes(x)) {  
              error = {
                ...error,
                policy: ["Please fill policy holder details "],
              };
            }
          });
        }
      }

      var proofAttributes = [
        'proofOfIdentity',
       'uniqueNumber',
       'personalFile',
       'proofOfAddress',
       'addressFile',
       'mainMemberFile',
        ]

        if (error || error !== undefined) {
          if (Object.keys(inputs)?.length) {
            Object.keys(error)?.map((x) => {
              if (proofAttributes?.includes(x)) {  
                error = {
                  ...error,
                  proof: ["Please fill proof of identity details "],
                };
              }
            });
          }
        }  

        var medicalAttributes = riskAttributes?.find((x) => x.code === "ADD")?.attributes.map((y) => y.name);
        if (error || error !== undefined) {
          if (Object.keys(inputs)?.length) {
            Object.keys(error)?.map((x) => {
              if (medicalAttributes?.includes(x)) {  
                error = {
                  ...error,
                  medical: ["Please fill medical history questions"],
                };
              }
            });
          }
        }

      if (!files?.personalFile) {
        error = {
          ...error,
          personalFile: ['Please upload the file']
        }
      } else {
        delete error?.personalFile
      }

      if (!files?.addressFile) {
        error = {
          ...error,
          addressFile: ['Please upload the file']
        }
      } else {
        delete error?.addressFile
      }

      if (!files?.mainMemberFile) {
        error = {
          ...error,
          mainMemberFile: ['Please upload the file']
        }
      } else {
        delete error?.mainMemberFile
      }
     

      if(error || error !== undefined){
        var errorArray = Object.keys(error);
        document.getElementById(errorArray[0])?.scrollIntoView({
          behavior: "smooth",
        });
        return setErrors(error);
      }

      const beneficiariesCount = beneficiaries.reduce(
        (a, b) => a + b.percentage,
        0
      );
  
      if (beneficiariesCount !== 100) {
        return setErrors({
          ...errors,
          ["beneficiariesSplit"]: [
            `Benefit split for all beneficiaries must be total to 100%`,
          ],
        });
      }

      setIsLoading(true);
      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: moment().format(),
              endDate: moment().add(1, 'year').subtract(1, 'day').format(),
              PolicyStatus: "Quoted",
              currencyCode: "NGN",
              members: [
                {
                  firstName: inputs?.firstName,
                  lastName: inputs?.lastName,
                  title: inputs?.title,
                  occupation: inputs?.occupation,
                  workCompany: inputs?.employer,
                  jobTitle: inputs?.position,
                  occupationIndustry: inputs?.business,
                  birthDate:moment(customAttributes.dateOfBirth).format("YYYY-MM-DD"),
                  mobilePhone: inputs?.mobilePhone,
                  mobilePhoneCode: `+${inputs?.mobilePhoneCode?.replace("+", "")}`,
                  nationalityId: inputs?.idNumber,
                  bvnNumber: inputs?.bvnNumber,
                  nationality: inputs?.nationality,
                  marriageStatus: inputs?.maritalStatus?.value,
                  gender: inputs?.mainGender,
                  telePhone: inputs?.telePhone,
                  address: inputs?.physicalAddress,
                  address1: inputs?.address2,
                  city: inputs?.city,
                  province: inputs?.region?.value,
                  postalAddress: inputs?.postalAddress,
                  height: riskAttributes?.find((x) => x.code === 'ADD')?.attributes?.find((y) => y.name === 'Height')?.value,
                  weight: riskAttributes?.find((x) => x.code === 'ADD')?.attributes?.find((y) => y.name === 'Weight')?.value,
                  postalCity: inputs?.postalCity,
                  postalProvince: inputs?.postalProvince?.value,
                  landlinePhone: inputs?.landlinePhone,
                  landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace(
                    "+",
                    ""
                  )}`,
                  eMail: inputs?.email,
                  memberType: 1,
                  relationship: 0,
                  workGPSCoordinate: inputs?.occupation
                },
              ],

              beneficiaries: beneficiaries.reduce((acc, x) => {
                acc = [
                  ...acc,
                  {
                    beneficiaryType: 0,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    country: x.country,
                    dob: x.dob,
                    birthDate: x.dob,
                    title: x.gender === "Male" ? "Mr" : "Miss",
                    nationalityId: x.nationalityId,
                    occupation: x.occupation,
                    address: x.address1,
                    address1: x.city,
                    address2: x.state,
                    address3:x.address2,
                    relationship: x.relationship && x.relationship.includes("-") ? x.relationship.split("-").join("").toLowerCase() : x.relationship,
                    eMail: x.email,
                    landlinePhone: x.telephoneNumber,
                    landlinePhoneCode: `+${x?.["Telephone Number Code"]?.replace('+','')}`,
                    postalAddress: x.postalAddress,
                    gender: x.gender,
                    mobilePhone: x.number,
                    mobilePhoneCode: `${x?.["Phone Number Code"]?.replace('+','')}`,
                    split: x.percentage / 100,
                  },
                ];
                if (x.guardianName) {
                  acc = [
                    ...acc,
                    {
                      beneficiaryType: 2,
                      firstName: x.guardianName,
                      lastName: x.guardianLastName,
                      dob: x.trusteeDob,
                      birthDate: x.guardianDob,
                      mobilePhone: x.guardianNumber,
                    },
                  ];
                }
                return acc;
              }, []),
              attributes:  [
           
                ...factors
                  ?.attributes.map((y) => ({
                    dataType: y.dataType,
                    name: y.name,
                    instanceId: y.instanceId,
                    value:y.name === 'Duration' ? customAttributes.duration ?? 5 : y.name === 'Sum Assured' ? customAttributes.sumAssured?.replace(',','') : y.name === 'Date of Birth' ? customAttributes.dateOfBirth : y.name === 'Payment Frequency' ? y.value?.replace('Price', '') : y.value
                  })),
          ],
              risks: [
                {
                  externalReference: uuidv4(),
                  attributes: reducerState?.riskAttributes
                    ?.find((x) => x.code === "MAM")
                    ?.attributes?.map((y) => ({
                      ...y,
                      value:
                        y.name === "Source of Fund"
                          ? inputs?.sourceOfFund?.value
                          : y.name === "Religion"
                          ? inputs?.religion
                          : y.name === "BVN Number"
                          ? inputs?.bvnNumber
                          : y.name === "Proof Of Identity Unique Number"
                          ? customAttributes?.uniqueNumber
                          : y?.value,
                    })),
                  valuations: calculatorResponse?.productOptions?.[0]?.risks?.[0]?.valuations?.values ?? [],
                  productRiskReference: reducerState?.riskAttributes?.find(
                    (x) => x.code === "MAM"
                  )?.instanceId,
                  price: calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.price,
                  discount: 0,
                  tax: 0,
                  total: calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total,
                },
                {
                  externalReference: uuidv4(),
                  attributes: reducerState?.riskAttributes?.find(
                    (x) => x.code === "ADD"
                  )?.attributes,
                  valuations: [],
                  productRiskReference: reducerState?.riskAttributes?.find(
                    (x) => x.code === "ADD"
                  )?.instanceId,
                  price: 0,
                  discount: 0,
                  tax: 0,
                  total: 0,
                },
              ],
              bankAccounts: null,
              productOptionReference:
                reducerState?.executeCalculator?.productOptions?.[0]
                  ?.productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "432105fb-8743-ec33-666f-55382f1587fc"
              : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? "e7c382b6-b5b6-4bff-446d-4a38d42b870a"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );
      let body = {
        externalReference: "CREATE_SALE_300",
        policySaleReference: res?.data?.data?.policySaleReference,
        policyAttachments: [
          {
            externalReference: "Proof of Address",
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            attachments: [
              {
                instanceId: "100",
                fileName: files?.addressFile?.name?.split(".")[0],
                fileExtension: files?.addressFile?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === attachments?.address
                ).instanceId,
              },
            ],
          },
          {
            externalReference: "Proof of Identity",
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            attachments: [
              {
                instanceId: "101",
                fileName: files?.personalFile?.name?.split(".")[0],
                fileExtension: files?.personalFile?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === attachments?.personal
                ).instanceId,
              },
            ],
          },
          {
            externalReference: "Photo of Main Member",
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            attachments: [
              {
                instanceId: "102",
                fileName: files?.mainMemberFile?.name?.split(".")[0],
                fileExtension: files?.mainMemberFile?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "POM"
                ).instanceId,
              },
            ],
          },
        ],
      };
      let file = [files?.addressFile, files?.personalFile, files?.mainMemberFile];
      let tags = Array(3)
        .fill()
        .map((_, i) => (100 + i).toString());
      await dispatch(policySaleAttachments({ body, file, tags }));
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("ng_complete_your_details_next_cta", {
        firstName: inputs?.firstName,
        lastName: inputs?.lastName,
        title: inputs?.title,
        occupation: inputs?.occupation,
        workCompany: inputs?.employer,
        jobTitle: inputs?.position,
        occupationIndustry: inputs?.business,
        birthDate: moment(customAttributes.dateOfBirth).format("YYYY-MM-DD"),
        mobilePhone: inputs?.mobilePhone,
        mobilePhoneCode: `+${inputs?.mobilePhoneCode?.replace("+", "")}`,
        nationalityId: inputs?.idNumber,
        bvnNumber: inputs?.bvnNumber,
        nationality: inputs?.nationality,
        marriageStatus: inputs?.maritalStatus?.value,
        gender: inputs?.mainGender,
        telePhone: inputs?.telePhone,
        address: inputs?.physicalAddress,
        address1: inputs?.address2,
        city: inputs?.city,
        province: inputs?.region?.value,
        postalAddress: inputs?.postalAddress,
        height: riskAttributes
          ?.find((x) => x.code === "ADD")
          ?.attributes?.find((y) => y.name === "Height")?.value,
        weight: riskAttributes
          ?.find((x) => x.code === "ADD")
          ?.attributes?.find((y) => y.name === "Weight")?.value,
        postalCity: inputs?.postalCity,
        postalProvince: inputs?.postalProvince?.value,
        landlinePhone: inputs?.landlinePhone,
        landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace("+", "")}`,
        eMail: inputs?.email,
        memberType: 1,
        relationship: 0,
        workGPSCoordinate: inputs?.occupation,
      });
      handleNext();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

    const handleBackTrack = () => {
      //trackUserEvents
      trackUserEvents("ng_complete_your_details_back_cta", {});
      handleBack();
    };

  const paymentFrequency = useSelector(
    (state) =>
      state?.products?.factors?.attributes?.find(
        (x) => x.name === "Payment Frequency"
      )?.value
  );

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">FlexiEdu Plan</h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">FlexiEdu Plan</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}

      <div className="d-flex mt-3 justify-content-center align-items-center flex-column">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>

        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <PolicyMember
            inputs={inputs}
            handleChangePolicy={handleChangePolicy}
            calculateMinDate={calculateMinDate}
            calculateMaxDate={calculateMaxDate}
            errors={errors}
            riskAtrributes={riskAttributes}
            dispatch={dispatch}
            width={width}
            customStyles={customStyles}
          />
          {errors && errors["policy"] && (
            <span className="errors">{errors["policy"][0]}</span>
          )}
          <div style={{ height: "20px" }} />
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    2
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Upload proof of identity here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <p className="primary-color f-700">
                  Select the type of document to be used as personal identifier*
                </p>
                <ReactSelect
                  name="proofOfIdentity"
                  id={"proofOfIdentity"}
                  styles={customStyles}
                  options={proofOfIdentity}
                  value={{
                    label: proofOfIdentity?.find(
                      (x) => x?.value === attachments?.personal
                    )?.label,
                    value: attachments?.personal,
                  }}
                  onChange={(e) => {
                    var obj = { ...attachments, personal: e.value };
                    dispatch(
                      updateCustomAttributes({
                        type: "ATTACHMENTS_STATE",
                        payload: obj,
                      })
                    );
                  }}
                />
                {errors?.["proofOfIdentity"] && (
                  <span className="text-danger errors">
                    {errors?.["proofOfIdentity"] &&
                      errors?.["proofOfIdentity"][0]}
                  </span>
                )}
                {attachments?.personal === "OTR" ? (
                  <TextInput
                    label={"Please specify"}
                    value={attachments?.personalExtra}
                    onChange={(e) => {
                      var obj = {
                        ...attachments,
                        personalExtra: e.target.value,
                      };
                      dispatch(
                        updateCustomAttributes({
                          type: "ATTACHMENTS_STATE",
                          payload: obj,
                        })
                      );
                      var attribute = riskAttributes.find(
                        (x) => x.code === "MAM"
                      )?.attributes;
                      var arr = [...attribute];
                      var index = attribute.findIndex(
                        (x) => x.name === "Other ID Document Type"
                      );
                      arr[index] = { ...arr[index], value: e.target.value };
                      dispatch(updatedAttributes(arr, "MAM"));
                    }}
                  />
                ) : (
                  <></>
                )}
                <TextInput
                  label={
                    "Please provide the unique number of the document type you have selected as proof of your identity*"
                  }
                  name="uniqueNumber"
                  errors={errors?.uniqueNumber}
                  value={customAttributes?.uniqueNumber}
                  onChange={(e) => {
                    if (e.target.value?.length >= 21) return;
                    dispatch(
                      updateCustomAttributes({
                        type: "CUSTOM_ATTRIBUTES",
                        payload: {
                          ...customAttributes,
                          uniqueNumber: e.target.value,
                        },
                      })
                    );
                  }}
                />
                <FileUpload
                  title={"Load up the document file as proof"}
                  id="personalFile"
                  name="personalFile"
                  onChange={handleFileUpload}
                  required={true}
                  value={files && files.personalFile}
                  errors={errors["personalFile"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <div
                  style={{
                    color: "#3C4096",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Acceptable file formats: JPG, JPEG, BMP, PNG or PDF, Max file
                  size: 2mb
                </div>
                <div style={{ height: "20px" }} />
                <p className="primary-color f-700">
                  Select the type of document to be used as evidence of address*
                </p>
                <ReactSelect
                  options={proofOfAddress}
                  styles={customStyles}
                  id={"proofOfAddress"}
                  name={"proofOfAddress"}
                  value={{
                    label: proofOfAddress?.find(
                      (x) => x.value === attachments?.address
                    )?.label,
                    value: attachments?.address,
                  }}
                  onChange={(e) => {
                    var obj = { ...attachments, address: e.value };
                    dispatch(
                      updateCustomAttributes({
                        type: "ATTACHMENTS_STATE",
                        payload: obj,
                      })
                    );
                  }}
                />
                {attachments?.address === "OTH" ? (
                  <TextInput
                    label={"Please specify"}
                    value={attachments?.addressExtra}
                    onChange={(e) => {
                      var obj = {
                        ...attachments,
                        addressExtra: e.target.value,
                      };
                      dispatch(
                        updateCustomAttributes({
                          type: "ATTACHMENTS_STATE",
                          payload: obj,
                        })
                      );
                      var attribute = riskAttributes.find(
                        (x) => x.code === "MAM"
                      )?.attributes;
                      var arr = [...attribute];
                      var index = attribute.findIndex(
                        (x) => x.name === "Other Address Document"
                      );
                      arr[index] = { ...arr[index], value: e.target.value };
                      dispatch(updatedAttributes(arr, "MAM"));
                    }}
                  />
                ) : (
                  <></>
                )}
                {errors?.["proofOfAddress"] && (
                  <span className="text-danger errors">
                    {errors?.["proofOfAddress"] &&
                      errors?.["proofOfAddress"][0]}
                  </span>
                )}
                <FileUpload
                  title={"Load up the document file as proof"}
                  id="addressFile"
                  name="addressFile"
                  onChange={handleFileUpload}
                  required={true}
                  value={files && files.addressFile}
                  errors={errors["addressFile"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <div
                  style={{
                    color: "#3C4096",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Acceptable file formats: JPG, JPEG, BMP, PNG or PDF, Max file
                  size: 2mb
                </div>
                <FileUpload
                  title={"Photo of Main Member"}
                  id="mainMemberFile"
                  name="mainMemberFile"
                  onChange={handleFileUpload}
                  required={true}
                  value={files && files.mainMemberFile}
                  errors={errors["mainMemberFile"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <div
                  style={{
                    color: "#3C4096",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Acceptable file formats: JPG, JPEG, BMP, PNG or PDF, Max file
                  size: 2mb
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["proof"] && (
            <span className="errors">{errors["proof"][0]}</span>
          )}

          <div style={{ height: "20px" }} />
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    3
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Medical history questions
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                {riskAttributes
                  ?.find((x) => x.code === "ADD")
                  ?.attributes?.filter((e) => !e.name.includes("YES", "Yes"))
                  ?.filter((x) => !doctorKeys.includes(x.name))
                  ?.filter(
                    (e) =>
                      e.name !== "Denied Life insurance" &&
                      e.name !== "If Yes Denied Life insurance" &&
                      e.name !== "Daily Number of Cigarettes "
                  )
                  ?.map((y) => renderQuestions(y, "medical"))}
              </div>

              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                  marginTop: 20,
                }}
              >
                <p className="primary-color f-700">
                  Usual medical doctor's name
                </p>
                {riskAttributes
                  ?.find((x) => x.code === "ADD")
                  ?.attributes?.filter((x) => doctorKeys.includes(x.name))
                  ?.map((y) => renderQuestions(y, "doctor"))}
              </div>

              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                  marginTop: 20,
                }}
              >
                <p className="primary-color f-700">
                  Declined insurance on your life
                </p>
                {riskAttributes
                  ?.find((x) => x.code === "ADD")
                  ?.attributes?.filter(
                    (e) => e.name === "Denied Life insurance"
                  )
                  ?.map((y) => renderQuestions(y, "medical"))}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["medical"] && (
            <span className="errors">{errors["medical"][0]}</span>
          )}

          <div style={{ height: "20px" }} />
          <Beneficiaries
            beneficiaries={beneficiaries}
            handleChangeBene={handleChangeBene}
            getRows={getRows}
            errors={errors}
            customStyles={customStyles}
            setBeneficiaries={setBeneficiaries}
            beneCount={beneCount}
            setBeneCount={setBeneCount}
            range={range}
            percentageArr={percentageArr}
            setIsTyped={setIsTyped}
            isTyped={isTyped}
            width={width}
            calculateage={calculateage}
            calculateBeneMinDate={calculateBeneMinDate}
            calculateBeneMaxDate={calculateBeneMaxDate}
            calculateGuardianMaxDate={calculateTrusteeMaxDate}
          />
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          {width > 1024 ? (
            <>
              <h5 className="text-white f-700">Total Premium</h5>
              <h5 className="text-white f-700">
                ₦{" "}
                {parseFloat(
                  calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                    ?.total
                )
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h5>
            </>
          ) : (
            <>
              {/* <span className="text-white f-700">Total {paymentFrequency} Premium</span> */}
              <h5 className="text-white f-700">Total Premium</h5>
              <span className="text-white f-700">
                ₦{" "}
                {parseFloat(
                  calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                    ?.total
                )
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </>
          )}
        </div>
      </div>

      <NavigationButtons
        isBackDisabled={isLoading}
        back={handleBackTrack}
        loading={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

const proofOfIdentity = [
  {
    label: "Driving licence",
    value: "DRL",
  },
  {
    label: "National ID",
    value: "NID",
  },
  {
    label: "International Passport",
    value: "INP",
  },
  {
    label: "Permanent Voters Card",
    value: "PVC",
  },
  ,
  {
    label: "Others",
    value: "OTR",
  },
];

const proofOfAddress = [
  {
    label: "Electricity bill",
    value: "ELB",
  },
  {
    label: "Water Bill",
    value: "WAB",
  },
  {
    label: "Phone Bill",
    value: "PHB",
  },
  {
    label: "Bank Statement",
    value: "BNS",
  },
  {
    label: "Tenancy Agreement",
    value: "TNA",
  },
  {
    label: "Waste Management Bill",
    value: "WMB",
  },
  {
    label: "Others",
    value: "OTH",
  },
]

export default Step2;


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#00000080'
  }),
}
