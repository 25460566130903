import { Divider } from "@mui/material";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { botswanapost, kazang, orangeMoney } from "../../assets/images";
import style from "../components.module.css";

const Summary = ({
  title,
  referenceNumber,
  isSummary = false,
  policyHolder,
  coverType,
  premiumLabel,
  policyHolderSurname,
  payDate,
  paymentRef ,
  premiumAmount,
  policyType,
  familyPolicy,
  coverAmount,
  extendedCover,
  riderBenefit,
  isSuccess = false,
  isFail = false,
  isPass = false
}) => {
  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const QUQPrevRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return (isSuccess ? QUQPrevRiskAttributes : QUQRiskAttributes)?.find(
      (x) => x?.name === value
    );
  };

  const dateOfBirth = getAttributes("Date of Birth");

  return (
    <div className={style.summary_container}>
      {isFail ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">Oops!</h3>
                    <p className="primary-color f-700 text-center">We seem to struggle with the payment transaction.</p>
                </Col>
            </Row> : isPass ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">All Done!</h3>
                    <p className="primary-color f-700 text-center">Payment successful</p>
                </Col>
            </Row> : <></>}

      <Row className="justify-content-between mt-4">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">Applicant :</p>
        </Col>
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">
            {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") <
            60
              ? `${getAttributes("Cover Option")?.value} Cover amount :`
              : `${getAttributes("Cover Option 60 Plus")?.value ==='Individual' ? 'Member only' : 'Member and family'}  policy :`} */}
              {premiumLabel ? `${premiumLabel} premium : ` : 'Premium : '} 
           
          </p>
        </Col>
        <Col lg={6}  xs={6}>
          {/* <p className="primary-color f-700 text-right w-100">{coverType === 0 ? 'Basic' : 'Comprehensive'}</p> */}
          <p className="primary-color f-700 text-right w-100">₦{premiumAmount ?? 'NA'}</p>
        </Col>
      </Row>

    { payDate ? <Row className="justify-content-between mt-0">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">Payment date :</p>
        </Col>
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {payDate}
          </p>
        </Col>
      </Row> : <></>
    }
    
    { paymentRef ? <Row className="justify-content-between mt-0">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">Payment reference number:</p>
        </Col>
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {paymentRef}
          </p>
        </Col>
      </Row> : <></>
    }



      {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") >
            60 && <Row className="justify-content-between mt-0">
        <Col lg={6}>
          <p className="primary-color f-700 text-left">Cover Amount :</p>
        </Col>
        <Col lg={6}>
          <p className="primary-color f-700 text-right w-100">{coverAmount}</p>
        </Col>
      </Row>} */}

      {extendedCover && <Row className="justify-content-between mt-0">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">
            Extended family cover :
          </p>
        </Col>
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {extendedCover}
          </p>
        </Col>
      </Row>}

      {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") < 60 &&
        riderBenefit && (
          <Row className="justify-content-between mt-0">
            <Col lg={6}  xs={6}>
              <p className="primary-color f-700 text-left">Rider benefit :</p>
            </Col>
            <Col lg={6}  xs={6}>
              <p className="primary-color f-700 text-right w-100">
                {riderBenefit}
              </p>
            </Col>
          </Row>
        )}

      {/* <Divider />

      <Row className="justify-content-between mt-4">
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-left">Monthly Premium :</p>
        </Col>
        <Col lg={6}  xs={6}>
          <p className="primary-color f-700 text-right w-100">
            P{premiumAmount}
          </p>
        </Col>
      </Row> */}

      <Divider />

    

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
        <p className="primary-color f-700 text-left m-0 w-100">
            Support contact details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* • insuranceinfo@fbninsurance.com */}
              wecare@sanlam.com.ng
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* • +234 1 905 4840 */}
              0700SANLAMLIFE
            </li>
          </ul>
        </Col>
      </Row>

      {!isSummary && (
        <>
          <Divider />
          <Row className="justify-content-start mt-3">
            <p className="primary-color f-700 text-center">
              Use the navigation bar on the left to return to the home page
            </p>
          </Row>
        </>
      )}
    </div>
  );
};

export default Summary;
