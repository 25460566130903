///-----passing query parameters---------///////////
import classNames from "classnames";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import { sanlam } from "../../../assets/images/index";
import {
  getProductPayment,
  generateCheckout,
  getPaymentStatus,
  issuePolicy,
  addPolicyBankDetails,
  approvePolicy,
  rejectPolicy,
  sendOTP,
  validateOTP,
} from "../../../store/actions/products";
import { getAgentOnboarding, login } from "../../../store/actions/auth";
import queryString from "query-string";

const PaymentSuccess = () => {
  const { width } = useWindowDimensions();
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  document.body.style = "background: #3C409610;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes?.find((x) => x?.name === value);
  };

  const mainMember = useSelector((state) => state?.prevState?.mainMember);
  const calculatorResponse = useSelector(
    (state) => state?.prevState?.executeCalculator
  );

  const dateOfBirth = getAttributes("Date of Birth");

  const parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };
 

  useEffect(async () => {
    await dispatch(login());
    //----GetPaymentstatus call-----///
    const res = await dispatch(
      getPaymentStatus(
        parseQuery(location.search)?.referenceNumber,
        parseQuery(location.search)?.saleNumber
      )
    );
    const code = res?.genericCode;
    var splitCode = code.split("");
    if (
      (splitCode[0] === "1" && splitCode[1] === "0") ||
      (splitCode[0] === "2" && splitCode[1] === "0")
    ) {
      const approveddata = {
        policyReference: parseQuery(location.search)?.referenceNumber,
        comment: "submitted from react front end",
      };
      // await dispatch(approvePolicy(approveddata));
         const token1 = parseQuery(location.search)?.token1;
         await dispatch(approvePolicy(token1, approveddata));
    } else {
      const rejecteddata = {
        externalReference: "LETSHEGO_SALE",
        comment: "Rejected from Letshego website",
        policyReference: parseQuery(location.search)?.referenceNumber,
      };
      // await dispatch(rejectPolicy(rejecteddata));
        const token1 = parseQuery(location.search)?.token1;
        await dispatch(rejectPolicy(token1, rejecteddata));
    }
  }, []);

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Educational Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">FlexiEdu plan </h3>
                  <h5 className="primary-color f-700"> Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">FlexiEdu plan </p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                title="FlexiEdu plan "
                isPass={true}
                referenceNumber={parseQuery(location.search)?.referenceNumber}
                policyHolder={parseQuery(location.search)?.applicant}
                policyHolderSurname={parseQuery(location.search)?.lastName}
                payDate={parseQuery(location.search)?.payDate}
                paymentRef ={parseQuery(location.search)?.paymentRef}
                isSuccess={true}
                isSummary={true}
                premiumAmount={parseFloat(parseQuery(location.search)?.premium)
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
              <div
                style={{ maxWidth: "460px", width: "100%" }}
                className="d-flex justify-content-center flex-column"
              >
                <img src={sanlam} style={{ height: "30px" }} />
                <p style={{ fontSize: 13, textAlign: "center", marginTop: 5 }}>
                  Underwritten by Sanlam Insurance Company Limited (Reg. No. RC
                  707564), a Licensed Life Insurer and an authorised Financial
                  Services Provider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
