/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from 'react';

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from 'react-bootstrap';

// Import image from assets folder
import { comingSoon, main, liberty, Insurance, sanlam } from '../../assets/images';

//   import custom component
import BodyButton from '../../components/Buttons/BodyButton';
import Card2 from '../../components/Card/Card2';
import { DateInput } from '../../components';

// import util to get current window dimensions
import useWindowDimensions from '../../utils/useWindowDimension';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getProductRisks, executeCalculator, updateCustomAttributes, getProductRisksAttributes,updatedAttributes, getProductTypeDetails, getProductAttachments, getProductAttributes } from '../../store/actions/products';
import { getAgentOnboarding, login, getAgentDetails, updateLoading } from '../../store/actions/auth';
import { ATTRIBUTES, RISKS } from '../../utils/constants';
import { calculator } from '../../components/calculator';
import Loader from '../../components/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactSlider from 'react-slider';
import { validate } from 'validate.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});
  

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [isFormLoading, setIsFormLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) => (x.sequence > y.sequence ? 1 : -1))
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) => (x.sequence > y.sequence ? 1 : -1))
  );

  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  
  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])

  const riskAttributes = useSelector((state) => state?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes);
  const reduxState = useSelector((state) => state);
  const customAttributes = useSelector((state) => state?.products?.customAttribues);
  const [dob, setDOB] = useState(null);
  const [sliderValue, setSliderValue] = useState(5);
  const [sum, setSum] = useState(null);
  const factors = useSelector((state) => state?.products?.factors);
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  const handleSubmit = async () => {
    try{
      // return  history('/forms');

      var err = validate(
        {
          dob: dob,
          sum: sum,
          cover: sliderValue,
        },
        schema
      );

      if (!dob) {
        return setErrors({ dob: ["Date of birth is required"] });
      }

      if (!sum) {
        return setErrors({ sum: ["Sum is required"] });
      }

      setIsLoading(true);
      setIsCounter(true);
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      await dispatch(login());
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductAttachments());
      const productTypes = await dispatch(getProductTypeDetails());
      const productAttributes = await dispatch(getProductAttributes());
      let risksAttrs = [];
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          risksAttrs = [
            ...risksAttrs,
            await dispatch(getProductRisksAttributes(risk.instanceId)),
          ];
        });
      };
      await act();

      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: productAttributes?.attributes.map((y) => ({
              dataType: y.dataType,
              name: y.name,
              instanceId: y.instanceId,
              value:
                y.name === "Duration"
                  ? sliderValue ?? 5
                  : y.name === "Sum Assured"
                  ? sum?.replace(",", "")
                  : y.name === "Date of Birth"
                  ? dob
                  : y.name === "Payment Frequency"
                  ? "Monthly"
                  : y.value ?? null,
            })),
            productTypeReference: productTypes.types[0].instanceId,
            risks: [
              {
                ...riskRes?.risks?.find((x) => x.code === "MAM"),
                attributes: [
                  ...risksAttrs
                    ?.find((x) => x.code === "MAM")
                    ?.attributes.map((y) => ({
                      value:
                        y.name === "Source of Fund"
                          ? "Salary"
                          : y.name === "Religion"
                          ? "None"
                            ? y.name === "BVN Number"
                            : "NA"
                          : "",
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                    })),
                ],
              },
            ],
          },
        })
      );

      setIsFormLoading(false);

      history("/forms")
      //trackUserEvents
      trackUserEvents("ng_edu_insurnace_get_a_quote_cta", {});
    }
    catch(err){
      setIsFormLoading(false);
      console.log(err);
    }
  }
  
  const calculateMinDate = (type) => {
    return moment().subtract(18, 'years')?.format('YYYY-MM-DD');
  };

  const handleBlur = (e) => {
      setSum(isNaN(parseFloat(e.target.value))? "" : parseFloat(e.target.value)?.toFixed(2)?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            sumAssured: e.target.value,
          },
        })
      );
  }
  

    return (
      <div>
        {width > 1024 ? (
          <Container>
            <div className="homepage-banner-pattern">
              <Row className="py-3 ">
                <Col lg={5} xs={12} className="text-center main__img_container">
                  <img className="main__img" src={main} alt="main" />
                </Col>
                <Col lg={7} xs={12} className="homepage__main__text">
                  <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                    FlexiEdu Plan
                  </Row>
                  <Row
                    className="homepage1__heading2 m-0 pt-1 justify-content-center"
                    style={{ marginLeft: "16px" }}
                  >
                    The best education to give your child is the one you have
                    always planned for them
                  </Row>
                  <Row className="py-4 m-0 justify-content-center">
                    <Col xs={6} md={4} className="p-0">
                      <p
                        style={{
                          marginLeft: "52px",
                          color: "#FBD105",
                          fontWeight: "bold",
                        }}
                      >
                        Get a quote now!
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row className="mt-0">
              <Col sm={12}>
                <Card2
                  width={width}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="homepage-banner-pattern">
            <Row
              className="py-3 justify-content-center"
              style={{ margin: "15px" }}
            >
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                  FlexiEdu Plan
                </Row>
                <Row
                  className="homepage1__heading2 m-0 pt-1 justify-content-center"
                  style={{ marginLeft: "16px" }}
                >
                  The best education to give your child is the one you have
                  always planned for them
                </Row>
              </Col>
              <Col
                lg={5}
                xs={12}
                className="text-center main__img_container"
                style={{ marginBottom: "30px" }}
              >
                <img className="main__img" src={main} alt="main" />
              </Col>
              <Row
                className="justify-content-center"
                style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
              >
                <Row
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "15px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "20px",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    FlexiEdu Plan
                  </h4>
                </Row>
                <Row>
                  <h4
                    style={{
                      fontSize: "14pt",
                      textAlign: "center",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Create an education fund for your child's future
                    <br />
                  </h4>
                </Row>
                <Row>
                  <div className="white_formbox d-flex justify-content-center flex-column">
                    <div className="d-flex  justify-content-center align-items-center flex-column w-100">
                      <p className="text-white f-700 m-0 pt-3">
                        When were you born?*
                      </p>
                      <input
                        type="text"
                        max={calculateMinDate()}
                        style={{ margin: 5, width: "250px", height: "34px" }}
                        onChange={(e) => {
                          dispatch(
                            updateCustomAttributes({
                              type: "CUSTOM_ATTRIBUTES",
                              payload: {
                                ...customAttributes,
                                dateOfBirth: e.target.value,
                              },
                            })
                          );
                          setDOB(e.target.value);
                        }}
                        placeholder={"dd/mm/yyyy"}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "date")}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        value={dob}
                        min={"1962-01-01"}
                      />
                      {errors?.dob ? (
                        <span className="errors" style={{ color: "#fff" }}>
                          {errors?.dob?.[0]}
                        </span>
                      ) : (
                        <></>
                      )}

                      <p className="text-white f-700 mt-3 mb-0">
                        What is your expected maturity amount?
                      </p>
                      <input
                        type="text"
                        style={{
                          margin: 5,
                          width: "250px",
                          fontSize: 14,
                          height: "34px",
                        }}
                        onBlurCapture={handleBlur}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          dispatch(
                            updateCustomAttributes({
                              type: "CUSTOM_ATTRIBUTES",
                              payload: {
                                ...customAttributes,
                                sumAssured: e.target.value,
                              },
                            })
                          );
                          setSum(e.target.value);
                        }}
                        value={sum}
                        placeholder="What is your expected maturity amount?"
                      />
                      {errors?.sum ? (
                        <span className="errors" style={{ color: "#fff" }}>
                          {errors?.sum?.[0]}
                        </span>
                      ) : (
                        <></>
                      )}

                      <p className="text-white f-700 mt-3 mb-0">
                        Choose cover duration: [{sliderValue} yrs]:
                      </p>
                      <ReactSlider
                        min={5}
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        max={20}
                        value={sliderValue}
                        onChange={(step) => {
                          dispatch(
                            updateCustomAttributes({
                              type: "CUSTOM_ATTRIBUTES",
                              payload: {
                                ...customAttributes,
                                duration: step,
                              },
                            })
                          );
                          setSliderValue(step);
                        }}
                      />
                      <div className="w-100 marker d-flex justify-content-between align-items-center flex-row">
                        <p>5 yr</p>
                        <p>10 yr</p>
                        <p>15 yr</p>
                        <p>20 yr</p>
                      </div>

                      {errors?.cover ? (
                        <span className="errors" style={{ color: "#fff" }}>
                          {errors?.cover?.[0]}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-warning quote_button"
                      >
                        {isFormLoading ? "Quoting" : "Get a quote"}
                      </Button>
                    </div>
                  </div>
                </Row>
                <Row
                  className="justify-content-center"
                  style={{
                    fontSize: "11pt",
                    color: "white",
                    textAlign: "center",
                    marginTop: "18px",
                    fontWeight: "bold",
                    marginBottom: "25px",
                  }}
                >
                  From: ₦5,000 / Monthly
                </Row>
                <Row>
                  <h4
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      marginTop: "15px",
                      fontSize: "12pt",
                    }}
                  >
                    Life Cover:
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <h4 style={{ fontSize: "11pt" }}>
                    Policyholder with beneficiaries Under 60 years
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <h4 style={{ fontSize: "11pt" }}>
                    Min monthly premium of ₦5,000
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <h4 style={{ fontSize: "11pt" }}>
                    Death benefit of ₦300,000
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <h4 style={{ fontSize: "11pt" }}>Min policy term: 5 years</h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <h4 style={{ fontSize: "11pt" }}>
                    Max policy term: 20 years
                  </h4>
                </Row>
                <br />
                {/* //Proof of identity */}
                <Row>
                  <h5
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      marginTop: "15px",
                      fontSize: "15pt",
                      textDecoration: "none",
                    }}
                  >
                    Required documents
                  </h5>
                </Row>
                <Row>
                  <h4
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      fontSize: "12pt",
                    }}
                  >
                    {/* Proof of identity: */}
                    Proof of Identification:
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <p style={{ fontSize: "11pt" }}>
                    <span style={{ fontSize: "11pt", fontWeight: "bold" }}>
                      e.g
                    </span>
                    &nbsp;&nbsp;
                    <span style={{ fontSize: "11pt" }}>
                      Driver's License / NIN / Voter's Card / International
                      Passport (Only 1 required )
                    </span>
                  </p>
                </Row>
                <Row>
                  <h4
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      fontSize: "12pt",
                    }}
                  >
                    Utility bill:
                  </h4>
                </Row>
                <Row className="justify-content-center text-center higlight_text">
                  <p style={{ fontSize: "11pt" }}>
                    <span style={{ fontSize: "11pt", fontWeight: "bold" }}>
                      e.g
                    </span>
                    &nbsp;&nbsp;
                    <span style={{ fontSize: "11pt" }}>
                      PHCN Bill / Waste Management Bill (within past 3 months)
                    </span>
                  </p>
                </Row>
                <br />

                <Row className="justify-content-center text-center text-white mt-2">
                  <h4 style={{ fontSize: "11pt", fontWeight: "bold" }}>
                    All valid claims are paid in less than 48 hours after all
                    the required claim documents have been submitted.
                  </h4>
                </Row>

                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                    marginTop: "10px",
                  }}
                />
                <Row
                  className="justify-content-center"
                  style={{
                    fontSize: "8pt",
                    color: "white",
                    textAlign: "center",
                    marginBottom: "5px",
                    padding: "14px",
                    fontWeight: "bold",
                  }}
                >
                  By opting to buy insurance, Letshego will share the required
                  information with the insurer to facilitate your policy
                  purchase.
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "white",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "1px",
                    }}
                  >
                    Policy underwritten by
                  </h6>
                </Row>
                <Row>
                  <div
                    className={`d-flex ${
                      width > 770
                        ? "justify-content-start"
                        : "justify-content-center"
                    }`}
                  >
                    <img
                      src={sanlam}
                      style={{
                        marginBottom: "15px",
                        width: "95px",
                        marginTop: "1px",
                      }}
                      alt="sanlam"
                    />
                  </div>
                </Row>
              </Row>
            </Row>
            {isLoading && (
              <div
                style={{
                  height: "99vh",
                  width: "100%",
                  zIndex: 100,
                  backgroundColor: "#00000050",
                  position: "fixed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "0px",
                }}
              >
                <div style={{ height: 100, width: 100 }}>
                  <CircularProgressbar
                    styles={buildStyles({
                      textColor: "#fbcf2c",
                      pathColor: "#fbcf2c",
                    })}
                    value={countDown}
                    text={`${countDown}%`}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
}

export default Homepage;

const data2 = [
  'There are two cover options, a Basic and a Comprehensive option so you can choose the one most suitable to your needs and financial circumstance.',
  'Each option covers a policyholder, one spouse and a maximum of six children with a choice to add parents and parents in law as well as extended family members.',
  'All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.',
];



const schema = {
  dob: {
    presence: {
      allowEmpty: false,
    },
   
  },
  sum: {
    presence: {
      allowEmpty: false,
    },
  },
  cover: {
    presence: {
      allowEmpty: false,
    },
    numericality: {
      greaterThan: 1,
    }
  }
}