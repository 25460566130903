// initial state of the reducer
const initialState = {
  policyAttachmentsOptions: [],
  risks: {},
  riskAttributes: [],
  types: {},
  selectedOption: null,
  companyDetails: {
    workCompanyOptions: "Company",
  },
  activeStep: 0,
  productOptionsNext: [],
  bankDetails: [],
  presistedRiskAttributes: [],
  tryCount: 0,
  isLoading: false,
  childAttributes: [],
  parentAttributes: [],
  extendedAttributes: [],
  protectionIndividual: [],
  protectionFamily: [],
  monthlyPremium: 0,
  premium: null,
  attachments: {},
  terms: false,
  policy: null,
  beneficiaries: [
    {
      firstName: '',
      lastName: '',
      email: '',
      number: null,
      percentage: '',
      "Guardian Phone Number Code": "+234",
    }
  ],
  accordion: {
    policy: true,
    spouse: false,
    child: false,
    extended: false,
    parent: false,
    beneficiaries: false
  },
  executeCalculator: {},
  customAttribues: {duration:5},
  otpResponse: {},
  factors: {},
  countDown: {count:0},
};

// function to update state
function products(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "PRODUCT_RISKS":
      return {
        ...state,
        risks: payload,
      };

    case 'FACTORS': {
      return{
        ...state,
        factors: {
          ...state.factors,
          attributes: payload
        }
      }
    }

    case 'ATTACHMENTS_STATE': {
      return {
        ...state,
        attachments: payload
      }
    }

    case 'CUSTOM_ATTRIBUTES':{
      return{
        ...state,
        customAttribues:{...state.customAttribues,...payload}
      }
    }

    case 'MONTHLY_PREMIUM': {
      return {
        ...state,
        monthlyPremium: payload
      }
    }

    case "PRODUCT_FACTORS": {
      return{
        ...state,
        factors: payload
      }
    }

    case 'MAIN_MEMBER_POLICY': {
      return{
        ...state,
        mainMember: payload
      }
    }

    case 'EXECUTE_CALCULATOR': {
      return{
        ...state,
        executeCalculator: payload
      }
    }

    case 'ACCORDION': {
      return {
        ...state,
        accordion: {
          ...state.accordion,
          ...payload
        }
      }
    }

    case 'BANDS':
      return {
        ...state,
        bands: payload
      }

    case 'TERMS':
        return{
          ...state,
          terms: payload
        }

    case 'CLEAR_RISK_STATE': {
      return{
        riskAttributes: payload,
        presistedRiskAttributes: payload,
        protectionIndividual: state.protectionIndividual,
        protectionFamily: state.protectionFamily,
        premium: null,
        terms: false,
        policy: null,
        beneficiaries: [
          {
            firstName: '',
            lastName: '',
            email: '',
            number: null,
            percentage: '',
            "Telephone Number Code": "+234",
            "Phone Number Code": "+234"
          }
        ],
        accordion: {
          policy: true
        },
        childAttributes: [],
        parentAttributes: [],
        extendedAttributes: [],
        activeStep: 0,
        types: state.types,
        customAttribues: {duration:5},
        countDown: {count:0},
      }
    }

    case 'OTP': 
    return {
      ...state,
      otpResponse: payload
    }
      
    case 'NUMBER_OF_PARENTS': 
      return{
        ...state,
        numberOfParents: payload
      }

    case 'BENEFICIARIES':
      return {
        ...state,
        beneficiaries: payload
      }
    case 'UPDATE_ATTRIBUTES': 
      return{
        ...state,
        riskAttributes: payload
      }

    case 'PROTECTION_INDIVIDUAL': 
      return {
        ...state,
        protectionIndividual: payload
      }

    case 'PREMIUM': 
      return{
        ...state,
        premium: payload
      }

    case 'PROTECTION_FAMILY': 
      return {
        ...state,
        protectionFamily: payload
      }
    case 'CHILD_ATTRIBUTES': 
      return{
        ...state,
        childAttributes: payload
      }

    case 'PARENT_ATTRIBUTES': 
      return{
        ...state,
        parentAttributes: payload
      }

    case 'EXTENDED_FAMILY_ATTRIBUTES': 
      return{
        ...state,
        extendedAttributes: payload
      }
    case 'UPDATE_T&C': 
      return {
        ...state,
        tCChecked: payload
      }

      case "POLICY_ATTACHMENT_OPTIONS":
      return {
        ...state,
        policyAttachmentsOptions: state.policyAttachmentsOptions ? [
          ...state.policyAttachmentsOptions,
          ...payload,
        ] :  [
          ...payload,
        ],
      };
    case "POLICY_ATTACHMENT":
      return {
        ...state,
        policyAttachments: payload,
      };

    case "PRODUCT_TYPES":
      return {
        ...state,
        types: {
          ...state.types,
          ...payload
        },
      };

    case "STORE_BUB_REQUESTED_BODY": 
      return {
        ...state,
        requestedPayload: payload
      }

    case "POLICY": 
      return {
        ...state,
        policy: payload
      }

    case "PRODUCT_RISKS_ATTRIBUTES":
      return {
        ...state,
        riskAttributes: [...state.riskAttributes, action.payload],
      };

    case "PRESISTED_PRODUCT_RISKS_ATTRIBUTES":
      return {
        ...state,
        presistedRiskAttributes: [...state.presistedRiskAttributes, action.payload],
      };
    case "PRODUCT_OPTIONS":
      return {
        ...state,
        productOptions: payload,
        productOptionsNext: action.payload1,
      };

    case "UPDATE_ATTRIBUTE_1":
      return {
        ...state,
        riskAttributes: payload,
      };

    case "SELECT_PRODUCT":
      return {
        ...state,
        selectedProduct: payload,
      };
    case "SELECTED_OPTION":
      return {
        ...state,
        selectedOption: payload,
      };

    case "UPDATE_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: payload,
      };

    case "CREATE_POLICY":
      return {
        ...state,
        policyResponse: payload,
      };

    case "LOADING": 
      return {
        ...state,
        isLoading: payload
      }
    case 'COUNTDOWN': {
        return {
          ...state,
          countDown: {...state.countDown,count:payload}
        }
    }  
    case "UPDATE_STEP":
      return {
        ...state,
        activeStep: payload,
      };

    case "BANK_OPTIONS":
      return {
        ...state,
        bankDetails: payload,
      };

    case "UPDATE_TRY_COUNT":
      return {
        ...state,
        tryCount: payload,
      };

    case "CLEAR_STATE":
      return initialState;

    // return default state if the type doesnt match any case
    default:
      return state;
  }
}
export default products;
