export const mainMemberSchema = (inputs) => {
  var schema = {
    firstName: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /^[-\sa-zA-Z]+$/,
        message: "^ Only alphabetic characters, hyphen and space are allowed",
      },
      length: {
        maximum: 30,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /^[-\sa-zA-Z]+$/,
        message: "^ Only alphabetic characters, hyphen and space are allowed",
      },
    },
    title: {
      presence: {
        allowEmpty: false,
      },
    },
   
    nationality: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Nationality only contains alphabets, numericals, hyphen and space",
      },
    },
    maritalStatus: {
      presence: {
        allowEmpty: false,
      },
    },
    mobilePhone: {
      presence: {
        allowEmpty: false,
        message: "^Mobile number can’t be blank"
      },
      format: {
        pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
        message: "^ Invalid mobile number",
      },
    },
    mobilePhoneCode: {
      presence: {
        allowEmpty: false,
        message: "^Mobile country code can’t be blank"
      },
      length: {
        maximum: 4,
        message: "^ Mobile country code is too long (max is 4 characters)",
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: "^Email can't be blank",
      },
      email: true,
    },
    politicallyExposed: {
      presence: {
        allowEmpty: false,
      },
    },
    physicalAddress: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9#,]+$/gm,
        message:
          "^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
      },
    },
    city: {
      presence: {
        allowEmpty: false,
      },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message:
            "^ Only alphabetic characters, hyphen and space are allowed",
      }
    },
    region: {
      presence: {
        allowEmpty: false,
        message: "^ State can't be blank"
      },
    },
    occupation: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Only alphabetic characters, numbers, hyphen and space are allowed",
      },
    },
    
    sourceOfFund: {
      presence: {
        allowEmpty: false,
      },
    },
    mainGender: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  if (inputs?.employer) {
    schema = {
      ...schema,
      employer: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if(inputs?.idNumber){
    schema = {
      ...schema,
      idNumber: {
        presence: {
          allowEmpty: false,
          message: "^ ID number can't be blank",
        },
        format: {
          pattern: /^[A-Za-z0-9]*$/,
          message: "^ Only alphabetic characters and numbers are allowed",
        },
      }
    };
  }

  if(inputs?.bvnNumber){
    schema = {
      ...schema,
      bvnNumber: {
        presence: {
          allowEmpty: false,
          message: "^ BVN number can't be blank",
        },
        format: {
          pattern: /^[0-9]*$/,
          message: "^ Only numbers are allowded",
        },
        length: {
          is: 11,
          message: "^ BVN number is the wrong length (should be 11 characters)",
        },
      }
    };
  }

  if (inputs?.landlinePhoneCode) {
    schema = {
      ...schema,
      landlinePhoneCode: {
        presence: {
          allowEmpty: false,
        },
        length: {
          maximum: 4,
          message: "^ Landline phone code is too long (max is 4 characters)",
        },
      },
    };
  }

  if (inputs?.position) {
    schema = {
      ...schema,
      position: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.business) {
    schema = {
      ...schema,
      business: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  
  if (inputs?.religion) {
    schema = {
      ...schema,
      religion: {
        presence: {
          allowEmpty: false,
        },
        format: {
          // Alphabets and space only
          pattern: /[-\sA-Za-z]+$/gm,
          message: "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.address2) {
    schema = {
      ...schema,
      address2: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9#,]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
        }
      },
    };
  }
  if (inputs?.postalAddress) {
    schema = {
      ...schema,
      postalAddress: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9#,]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
        }
      },
    };
  }
  if (inputs?.postalCity) {
    schema = {
      ...schema,
      postalCity: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        }
      },
    };
  }
  if (inputs?.postalProvince) {
    schema = {
      ...schema,
      postalProvince: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }
  return schema;
};

export const proofOfIdentitySchema = (attchments) => {
  var schema = {
    proofOfIdentity: {
      presence: {
        allowEmpty: false,
        message: "^ Personal identifier can't be blank",
      },
    },
    proofOfAddress: {
      presence: {
        allowEmpty: false,
        message: "^ Evidence of address can't be blank",
      },
    },
    uniqueNumber: {
      presence: {
        allowEmpty: false
      },
      format: {
        pattern: /^[a-zA-Z0-9_.-]*$/,
        message: "^ Only alphabetic characters and numbers are allowed"
      }
    }
  };

  return schema;
};

export const beneficiariesSchema = (
  beneCount,
  getRows,
  calculateage,
  beneficiaries
) => {
  var val = {};
  getRows(beneCount)?.map((x, i) => {
    val = {
      ...val,
      [`Beneficiary First Name ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^First name can't be blank",
        },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message:
            "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
      [`Beneficiary Last Name ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^Last name can't be blank",
        },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message:
            "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
      [`Address 1 ${i+1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Address 1 can't be blank"
        },
        format: {
          pattern: /[-\sA-Za-z0-9#,]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
        }
      },
      [`city ${i+1}`]: {
        presence: {
          allowEmpty: false
        },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message:
            "^ Only alphabetic characters, hyphen and space are allowed",
        }
        
      },
      [`state ${i+1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ State can't be blank"
        },
        
      },
      [`Beneficiary Date of Birth ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^Date of birth can't be blank",
        },
      },
      [`Gender ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Gender can't be blank",
        },
      },
      [`Phone Number ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Mobile number can't be blank",
        },
        format: {
          pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
          message: "^ Invalid mobile number",
        },
      },
      [`Phone Number Code ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Mobile country code can’t be blank",
        },
        length: {
          maximum: 4,
          message: "^ Mobile country code is too long (max is 4 characters)",
        },
      },
      [`Percentage ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Percentage can't be blank",
        },
      },
    };
    if(beneficiaries[i]?.["email"]){
      val = {
        ...val,
        [`Email ${i + 1}`]: {
          email: true,
        },
      }
    }
    if(beneficiaries[i]?.address2){
      val = {
        ...val,
        [`Address 2 ${i+1}`]: {
          format: {
            pattern: /[-\sA-Za-z0-9#,]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, comma, hyphen and space are allowed",
          }
        },
      }
    }
    if (beneficiaries[i]?.occupation) {
      val = {
        ...val,
        [`Occupation ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Occupation can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z0-9]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, hyphen and space are allowed",
          }
        },
      };
    }
    if (beneficiaries[i]?.["Telephone Number Code"]) {
      val = {
        ...val,
        [`Telephone Number Code ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Telephone number code can't be blank",
          },
          length: {
            maximum: 4,
            message: "^Telephone number code is too long (max is 4 characters)",
          },
        },
      };
    }
    if (calculateage(beneficiaries[i]?.["dob"])) {
      val = {
        ...val,
        [`Guardian First Name ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^First name can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z]+$/gm,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
          length: {
            maximum: 30,
          },
        },
        [`Guardian Last Name ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^Last name can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z]+$/gm,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
          length: {
            maximum: 30,
          },
        },
        [`Guardian Phone Number ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^Mobile number can't be blank",
          },
        },
        [`Guardian Phone Number Code ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Guardian phone number code can't be blank",
          },
        },

        [`Guardian dob ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^Date of birth can't be blank",
          },
        },
      };
    }
  });

  return val;
};

export const medicalQuestions = (risk) => {
  var attributes = risk?.find((x) => x.code === "ADD")?.attributes;
  var constraints = {};
  attributes?.forEach((x, i) => {
      if (x.name.toLowerCase().includes("if yes")) {
        var index = attributes?.findIndex(
          (f) => f.instanceId === x?.instanceId
        );
        if (attributes?.[index - 1]?.value === "YES") {
          constraints = {
            ...constraints,
            [x.name]: {
              presence: {
                allowEmpty: false,
                message: "^ Can't be blank",
              },
            },
          };
        }
        return;
      }

      if (x.name === "Daily Number of Cigarettes ") {
        var index = attributes?.findIndex(
          (f) => f.instanceId === x?.instanceId
        );
        if (attributes?.[index - 1]?.value === "YES") {
          constraints = {
            ...constraints,
            [x.name]: {
              presence: {
                allowEmpty: false,
                message: "^ Details can't be blank",
              },
            },
          };
        }
        return;
      }

      if (x.name === "Units of Alcohol per Day ") {
        var index = attributes?.findIndex(
          (f) => f.instanceId === x?.instanceId
        );
        if (attributes?.[index - 1]?.value === "YES") {
          constraints = {
            ...constraints,
            [x.name]: {
              presence: {
                allowEmpty: false,
                message: "^ Details can't be blank",
              },
              format: {
                pattern: /^[0-9]*$/,
                message: "^ Only numbers are allowded",
              },
            },
          };
        }
        return;
      }

      if(x.name === 'Hospital Address' || x.name === 'Doctors Mobile') return;

      if (x.name === "Doctors name" || x.name === "Name of Hospital") {
        constraints = {
          ...constraints,
          [x.name]: x.value?.length ?{
            presence: {
              allowEmpty: true,
            },
            format: {
              // Alphabets and space only
              pattern: /[-\sA-Za-z]+$/gm,
              message:
                "^ Only alphabetic characters, hyphen and space are allowed",
            },
          } : null,
        };
      } else if (x.name === "Patient") {
        constraints = {
          ...constraints,
          [x.name]: x.value?.length ? {
            presence: {
              allowEmpty: true,
            },
            format: {
              pattern: /[-\sA-Za-z0-9]+$/gm,
              message:
                "^ Only alphabetic characters, numbers, hyphen and space are allowed",
            }
          } : null,
        };
      } else if(x.name.match('MH')) {
        constraints = {
          ...constraints,
          [x.name]: {
            presence: {
              allowEmpty: false,
              message: "^ Can't be blank"
            },
          },
        };
      } else {
        constraints = {
          ...constraints,
          [x.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      }
    // }
  });
  return constraints;
};
