import { Accordion, Divider } from "@mui/material";
import classNames from "classnames";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { DateInput, TextInput, TwoButtons } from "../../../components";
import Summary from "../../../components/Forms/Summary";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  getProductPayment,
  generateCheckout,
  issuePolicy,
  addPolicyBankDetails,
  policySaleAttachments,
  policySaleAttachmentsBase64,
  sendOTP,
  validateOTP,
  approvePolicy,
  UpdateSteps,
} from "../../../store/actions/products";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import termsandconditions from "../../../assets/documents/FlexiEdu_T&C.pdf";
import style from "../Form.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { validate } from "validate.js";
import { Col, Row } from "react-bootstrap"; 
import SignatureCanvas from 'react-signature-canvas';
import ReactSelect from "react-select";
import { trackUserEvents } from "../../../utils/utils";



const Step6 = ({ handleNext, handleBack, files, handleFormBack }) => {
  const [attributes, setAttributes] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );
  const attOpts = useSelector(
    (state) => state.products?.policyAttachmentsOptions
  );
  const factors = useSelector((state) => state?.products?.factors?.attributes);
  const premiumAmount = useSelector((state) => state?.products?.premium);
  const policy = useSelector((state) => state?.products?.policy);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [isForm, setIsForm] = useState(false);
  const [generateForm, setGenerateForm] = useState("");
  const [terms, setTerms] = useState({
    aggreement: null,
    terms: false,
    declaration: false,
  });
  const activeStep = useSelector((state) => state.products.activeStep);
  const agentOnBoardingToken = useSelector(
    (state) => state?.auth?.agentOnboarding?.jwtToken
  );
  const amount = calculatorResponse?.productOptions[0]?.risks[0]?.rating?.total
  const poliyholderName = mainMember?.firstName
  const poliyLastName = mainMember?.lastName
  const signPad = useRef();
  const [signUrl, setSignUrl] = useState("");
  const [signFile, setSignFile] = useState("");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);

  const handleChange = (evt) => {
    setAttributes({
      ...attributes,
      [evt.target.name]: evt.target.value,
    });
  };

  useEffect(() => {
    if (!files || Object.keys(files).length === 0) {
      dispatch(UpdateSteps(activeStep - 1));
      handleFormBack(activeStep - 1);
      forceUpdate();

      return;
    }
  }, []);
  const date = new Date()
  var payDate = moment(date).format("YYYY-MM-DD");

  const handleSubmit = async () => {
    try {
      const error = validate(attributes, schema);
      if (error || error !== undefined) {
        return setErrors(error);
      }

      if (!files || Object.keys(files).length === 0) {
        dispatch(UpdateSteps(activeStep - 1));
        handleFormBack(activeStep - 1);

        forceUpdate();
        return;
      }

      setIsLoading(true);
      ////--addPolicySaleAttachments--starts----//////
      let body = {
        externalReference: "CREATE_SALE_300",
        policySaleReference: policy.policySaleReference,
        policyAttachments: [
          {
            externalReference: "Proof of Signature",
            policyReference: policy.policies[0].policyReference,
            byteAttachments: [
              {
                AttachmentByteArray: signFile,
                FileName: "Signature",
                FileExtension: "jpg",
                ProductAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "S001"
                ).instanceId,
              },
            ],
          },
        ],
      };
      await dispatch(policySaleAttachmentsBase64(body));
      ////--addPolicySaleAttachments--ends----//////
      const data = {
        type: attributes.type,
        pennyDropStatus: "0",
        pennyDropMessage: null,
        bank: attributes.bank,
        accountNumber: attributes.accountNumber,
        accountHolder: attributes.accountHolder,
        branch: attributes.branch,
        branchCode: attributes.branchCode,
        iban: "IB-200",
        swift: "SW-200",
        policyCollectionSchedule: {
          collectionDay: attributes.coverDate.split("-")[2],
          collectionDuration: 0,
          collectionFrequency: "3",
          policyFirstCollection: {
            firstCollectionAmount: parseFloat(
              calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
            )
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            FirstCollectionDate: attributes.coverDate,
            IsTaxInclusive: true,
            FirstCollectionTaxAmount: "0",
            Comments:
              policy.policies?.length && policy.policies[0].policyReference,
          },
        },
      };

      const res1 = await dispatch(
        addPolicyBankDetails(
          policy.policySaleReference,
          policy.policies[0].policyReference,
          data
        )
      );

      //----nigeria payment methods calls start-----////
      const product_Payment = await dispatch(getProductPayment());
      if (product_Payment) {
        const data = {
          paymentInstanceId: product_Payment.productPayment[0].configurationKey, //crct
          email: mainMember?.email,
          companyId: product_Payment.companyInstanceId,
          policyReferenceId: policy && policy?.policies[0]?.policyReference,
          policySaleReference: policy?.policySaleReference,
          amount: parseFloat(
            calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
          )?.toFixed(2),
          currency: "NGN",
          checkoutType: 1,
          shopperSuccessUrl: `${window.location.origin}/payment-success?referenceNumber=${policy?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${amount}&saleNumber=${policy?.policySaleReference}&lastName=${poliyLastName}&payDate=${payDate}&token1=${agentOnBoardingToken}`,
          shopperFailureUrl: `${window.location.origin}/payment-failure?referenceNumber=${policy?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${amount}&saleNumber=${policy?.policySaleReference}&lastName=${poliyLastName}&payDate=${payDate}&token1=${agentOnBoardingToken}`,
        };
        const generate_Checkout = await dispatch(generateCheckout(data));
        if (generate_Checkout?.data?.data?.form) {
          dispatch(
            storeState({ generateCheckout: generate_Checkout?.data?.data })
          );
          setIsForm(true);
          setGenerateForm(generate_Checkout?.data?.data?.form);
        }
      }
      //trackUserEvents
      trackUserEvents("ng_application_payment_details_confirm_cta", {});
      setIsLoading(false);
      dispatch(storeState());

      //---nigeria payment methods calls ends -----////
    } catch (err) {
      setIsLoading(false);
    }
  };

  const paymentFrequency = useSelector(
    (state) =>
      state?.products?.factors?.attributes?.find(
        (x) => x.name === "Payment Frequency"
      )?.value
  );

  const paymentScript = useSelector(
    (state) => state?.prevState?.generateCheckout?.script
  );

  useEffect(() => {
    if(paymentScript){
      let myScript = paymentScript;
      myScript = myScript.replace("<script>", "");
      myScript = myScript.replace("</script>", "");
      const script = document.createElement("script");
  
      script.innerHTML = myScript;
  
      document.body.appendChild(script);
    }
  },[paymentScript])

  const handleClear=() => {
    setSignUrl(signPad.current.clear())
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});

}


  const handleConfirmSign = async() => {
    await setSignUrl(signPad.current.getTrimmedCanvas().toDataURL('image/jpg'))
    await urlToFile(signPad.current.getTrimmedCanvas().toDataURL('image/jpg'));
  }

  const urlToFile = (url) => {
    let arr = url.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let data = arr[1];

    let file = new File([url], 'Sign.png', {type: mime});
    setSignFile(data)

  }


  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">FlexiEdu plan</h3>
            <h2 className="primary-color f-800">
              Application & payment details
            </h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">FlexiEdu plan </p>
          <h2 className="primary-color f-800">Application & payment details</h2>
        </div>
      )}

      <div className="mt-4 h-100">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
          {isForm ? (
            isForm && (
              <>
                <Row className={classNames("mt-5")}>
                  <div
                    dangerouslySetInnerHTML={{ __html: generateForm }}
                    style={{ width: "560px", height: "375px" }}
                  />
                </Row>
              </>
            )
          ) : (
            <>
              <Summary
                isSummary={false}
                title="Nigeria Funeral Plan"
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={mainMember?.firstName ?? "NA"}
                policyHolderSurname={mainMember?.lastName ?? "NA"}
                premiumAmount={parseFloat(
                  calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                    ?.total
                )
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                premiumLabel={
                  factors?.find((x) => x.name === "Payment Frequency")?.value
                }
              />
              <Accordion
                defaultExpanded={true}
                style={{ maxWidth: "460px", width: "100%" }}
              >
                <AccordionDetails className="p-0 mt-4">
                  <div
                    style={{
                      border: "1px solid #00000030",
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    <p className="primary-color f-700">
                  Please provide your bank details for recurring premium payments and claim settlement.
                </p>
                    <TextInput
                  label="Account holder name*"
                  placeholder="Full name"
                  value={attributes.accountHolder}
                  name="accountHolder"
                  onChange={handleChange}
                  errors={errors.accountHolder}
                />
                <TextInput
                  label="Account number*"
                  placeholder="Your account number"
                  value={attributes.accountNumber}
                  name="accountNumber"
                  onChange={(e) => {
                    if(e.target.value?.length <=10){
                      handleChange(e)
                    }
                  }}
                  errors={errors.accountNumber}
                />
                <TextInput
                  title="Account type"
                  label="Account type"
                  value={attributes.type}
                  name="type"
                  placeholder=" Your account type"
                  onChange={handleChange}
                  errors={errors.type}
                />
                <div className="form-group mt-3">
            <label className="form-label">Bank name*</label>
            <ReactSelect
              id={`bank`}
              placeholder="Select bank"
              styles={customStyles}
              value={
                attributes.bank?.value
                  ? attributes.bank?.value
                  : { label: attributes.bank, value: attributes.bank }
              }
              options={[
                {
                  label: "Access Bank",
                  value: "Access Bank",
                },
                {
                  label: "Citi Bank",
                  value: "Citi Bank",
                },
                {
                  label: "Diamond Bank",
                  value: "Diamond Bank",
                },
                {
                  label: "Eco Bank",
                  value: "Eco Bank",
                },
                {
                  label: "Enterprise Bank",
                  value: "Enterprise Bank",
                },
                {
                  label: "Fidelity Bank",
                  value: "Fidelity Bank",
                },
                {
                  label: "First Bank of Nigeria",
                  value: "First Bank of Nigeria",
                },
                {
                  label: "First City Monument Bank(FCMB)",
                  value: "First City Monument Bank(FCMB)",
                },
                {
                  label: "Guaranty Trust Bank(GTB)",
                  value: "Guaranty Trust Bank(GTB)",
                },
                {
                  label: "Heritage Bank",
                  value: "Heritage Bank",
                },
                {
                  label: "Jaiz Bank",
                  value: "Jaiz Bank",
                },
                {
                  label: "KeyStone Bank",
                  value: "KeyStone Bank",
                },
                {
                  label: "Mainstreet Bank",
                  value: "Mainstreet Bank",
                },
                {
                  label: "Skye Bank",
                  value: "Skye Bank",
                },
                {
                  label: "Spring Bank",
                  value: "Spring Bank",
                },
                {
                  label: "Stanbic IBTC Bank",
                  value: "Stanbic IBTC Bank",
                },
                {
                  label: "Standard Chartered Bank",
                  value: "Standard Chartered Bank",
                },
                {
                  label: "Sterling Bank",
                  value: "Sterling Bank",
                },
                {
                  label: "Union Bank",
                  value: "Union Bank",
                },
                {
                  label: "United Bank for Africa(UBA)",
                  value: "United Bank for Africa(UBA)",
                },
                {
                  label: "Unity Bank",
                  value: "Unity Bank",
                },
                {
                  label: "Wema Bank",
                  value: "Wema Bank",
                },
                {
                  label: "Zenith Bank",
                  value: "Zenith Bank",
                },
              ]}
              onChange={(e) => {
                handleChange({
                  target: { name: "bank", value: e.value },
                });
              }}
            />
            {errors?.["bank"] && (
              <span className="text-danger errors">
                {errors?.["bank"] && errors?.["bank"][0]}
              </span>
            )}
          </div>
                <DateInput
                  label={"Debit order date"}
                  name={"coverDate"}
                  minDate={new Date()}
                  onChange={handleChange}
                  required={true}
                  errors={errors?.coverDate}
                />
                    <p className="mt-3" style={{ fontSize: "14px" }}>
                      I hereby affirm that in line with the Nigeria Data
                      Protection Regulation, 2019 (NDPR), I consent to the
                      collection and processing of my personal data/information
                      (within or outside Nigeria) in the absence of any fraud,
                      duress, undue influence or coercion for the purpose of
                      forming the basis of this insurance proposal/contract and
                      other necessary data processing activities which may arise
                      therefrom, including for the performance of the insurance
                      contract between myself and Sanlam Nigeria. I affirm that I
                      have the requisite capacity under the law to consent to
                      the collection and processing of my personal data. I
                      further consent to the processing of my personal data,
                      including transfer of my personal data to any third party
                      for reasons associated with the purpose for which the data
                      is being processed as stated above or processing for the
                      marketing of Sanlam Nigeria’s existing or future
                      products. I authorize and consent that medical
                      practitioners who may be in possession of, or hereafter
                      acquire any information pertaining to my medical records
                      may disclose such information to Sanlam Nigeria. I affirm
                      that I am aware and take cognizance of my rights under the
                      NDPR which include the right to request for access,
                      amendment, rectification or cancellation or destruction of
                      my personal data/information, the right to lodge complaint
                      with the relevant authority as well as the right to object
                      to the processing of my personal data.
                    </p>
                    <TwoButtons
                      isField={true}
                      half={true}
                      state={terms.aggreement}
                      errors={errors["aggreement"]}
                      onClick={(e) => setTerms({ ...terms, aggreement: e })}
                      options={[
                        {
                          name: "Yes",
                          value: 1,
                        },
                      ]}
                    />
                    <h6 className="mt-3">Declarations</h6>
                    <p style={{ fontSize: "14px" }}>
                      I agree that this application is subject to Policy terms
                      and conditions. I understand that the information provided
                      by me and any documents required by the company shall be
                      the basis of the policy. I declare that all the
                      information provided by me, whether in my own handwriting
                      or not, is true and complete.
                      <br />
                      I irrevocably authorise:
                      <br />
                      1. The Company to obtain from any person, whom I hereby so
                      authorise and request to give, any information which the
                      company deems necessary, and to share with other insurers
                      that information and any information contained in this
                      application or any related policy or other document.
                      <br />
                      2. I acknowledge that I read and understood these
                      declarations.
                    </p>
                    <TwoButtons
                      isField={true}
                      half={true}
                      state={terms.declaration}
                      errors={errors["declaration"]}
                      onClick={(e) => setTerms({ ...terms, declaration: e })}
                      options={[
                        {
                          name: "Yes",
                          value: 1,
                        },
                      ]}
                    />

                    <Divider />
                    <div className=" mt-3 d-flex justify-content-between flex-row align-items-start">
                      <TwoButtons
                        isField={true}
                        half={true}
                        onClick={(e) => setTerms({ ...terms, terms: e })}
                        state={terms.terms}
                        errors={errors?.["terms"]}
                        options={[
                          {
                            name: "Yes",
                            value: 1,
                          },
                        ]}
                      />
                      <p style={{ fontSize: "14px" }}>
                        By ticking this box, I accept that all recurring premiums will be collected as per my selected 
                        frequency from the above mentioned account. I further declare that I have read and understood
                        the product <a  style={{ color: "#3C4096" }} rel="noreferrer"
                        href={termsandconditions}
                        target="_blank"> terms and conditions</a>.
                      </p>
                    </div>
                    <Divider />
                    {/* //---Signature starts----//// */}
                    <div className="mt-3">
                      <p style={{color:"#3C4096", fontWeight:"700"}}>Signature:</p>
                    <div style={{
                      border: "1px solid #00000030",
                      padding: 20,
                      borderRadius: 5,
                    }}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                      ref={signPad}
                    />
                    </div>
                    <div>
                    <Row className="mt-2">
                    <Col lg={6} xs={6} className="d-flex justify-content-start">
                    <button className="clear-button" onClick={handleClear}>
                      Clear
                    </button>
                    </Col>
                    <Col lg={6} xs={6} className="d-flex justify-content-end">
                    <button className="confirm-sign-button" onClick={handleConfirmSign}>
                     Confirm Signature
                    </button>
                    </Col>
                    </Row>
                    </div>
                    {signUrl ? <img  style={{width:"60px"}}src={signUrl} /> : null}
                  </div>  
                  {/* //---Signature ends----//// */}  
                    <div style={{ height: "20px" }} />
                    <button
                      className={style.confirm_purchase}
                      onClick={() => {
                        if (
                          !terms?.terms ||
                          !terms?.declaration
                        ) {
                          return setErrors({
                            ...errors,
                            declaration: !terms?.declaration
                              ? ["Please review the declaration"]
                              : [],
                            terms: !terms?.terms
                              ? ["Please review the terms"]
                              : [],
                            aggreement: !terms?.aggreement
                              ? ["Please review the aggreements"]
                              : [],
                          });
                        } else {
                          setErrors({
                            ...errors,
                            terms: [],
                            declaration: [],
                            aggreement: [],
                          });
                          handleSubmit();
                        }
                      }}
                    >
                      {isLoading ? (
                        <div class="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        "Confirm & Submit"
                      )}
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total  Premium</h5>
          <h5 className="text-white f-700">
            ₦{" "}
            {parseFloat(
              calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
            )
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={() => {
          //trackUserEvents
          trackUserEvents("ng_application_payment_details_back_cta", {});
          handleBack();
        }}
        payment={true}
        isBackDisabled={isLoading}
        next={() => {
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  accountHolder: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /[-\sA-Za-z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  accountNumber: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[0-9]*$/,
      message: "^ Only numbers are allowed",
    },
    length: {
      minimum: 10,
      maximum: 10,
      message: "^Account number must be 10 digits"
    }
  },
  bank: {
    presence: {
      allowEmpty: false,
      message: "^Bank name can't be blank",
    },
    format: {
      pattern: /[-\sA-Za-z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  coverDate: {
    presence: {
      allowEmpty: false,
      message: "^ Debit order date can't be blank",
    },
  },
};

export default Step6;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#00000080'
  }),
}
