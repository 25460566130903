// default imports for react project
import React, { useState, useEffect }  from 'react';

// import default styles from react-bootstrap package
import { Col, Row } from 'react-bootstrap';

// Import images from assets folder

//   import custom component
import BodyButton from "../Buttons/BodyButton";
import { box , liberty, Insurance, Educare, sanlam } from "../../assets/images";
import { Navigate, useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getProductRisks, executeCalculator, updateCustomAttributes, getProductRisksAttributes,updatedAttributes, getProductTypeDetails, getProductAttachments, getProductAttributes } from '../../store/actions/products';
import { getAgentOnboarding, login } from '../../store/actions/auth';
import { ATTRIBUTES, RISKS } from "../../utils/constants";
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactSlider from 'react-slider';
import { validate } from 'validate.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";


// React functional component starts here..
function Card2({ width, setIsLoading, isLoading }) {  
  const location = useLocation();
  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const [dob, setDOB] = useState(null);
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const [errors, setErrors] = useState({});
  const [sliderValue, setSliderValue] = useState(5);
  const [sum, setSum] = useState(null);
  const factors = useSelector((state) => state?.products?.factors);
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const customAttributes = useSelector((state) => state?.products?.customAttribues);
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

 
  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])

  const handleSubmit = async () => {
    try{
      if (!dob) {
        return setErrors({ dob: ["Date of birth is required"] });
      }

      if (!sum) {
        return setErrors({ sum: ["Sum is required"] });
      }

      setIsLoading(true);
      setIsCounter(true);
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      await dispatch(login());
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductAttachments());
      const productTypes = await dispatch(getProductTypeDetails());
      const productAttributes = await dispatch(getProductAttributes());
      let risksAttrs = [];
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          risksAttrs = [
            ...risksAttrs,
            await dispatch(getProductRisksAttributes(risk.instanceId)),
          ];
        });
      };
      await act();
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: productAttributes?.attributes.map((y) => ({
              dataType: y.dataType,
              name: y.name,
              instanceId: y.instanceId,
              value:
                y.name === "Duration"
                  ? sliderValue ?? 5
                  : y.name === "Sum Assured"
                  ? sum?.replace(",", "")
                  : y.name === "Date of Birth"
                  ? dob
                  : y.name === "Payment Frequency"
                  ? "Monthly"
                  : y.value ?? null,
            })),

            productTypeReference: productTypes.types[0].instanceId,
            risks: [
              {
                ...riskRes?.risks?.find((x) => x.code === "MAM"),
                attributes: [
                  ...risksAttrs
                    ?.find((x) => x.code === "MAM")
                    ?.attributes.map((y) => ({
                      value:
                        y.name === "Source of Fund"
                          ? "Salary"
                          : y.name === "Religion"
                          ? "None"
                            ? y.name === "BVN Number"
                            : "NA"
                          : "",
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                    })),
                ],
              },
            ],
          },
        })
      );

      setIsLoading(false);

      history("/forms");

      //trackUserEvents
      trackUserEvents("ng_edu_insurnace_get_a_quote_cta", {});
    }
    catch(err){
      setIsLoading(false);
      console.log(err);
    }
  }
  
  const calculateMinDate = (type) => {
    return moment().subtract(18, 'years')?.format('YYYY-MM-DD');
  };

  const handleBlur = (e) => {
      setSum(isNaN(parseFloat(e.target.value))? "" : parseFloat(e.target.value)?.toFixed(2)?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            sumAssured: e.target.value,
          },
        })
      );
    // }
  }
  

  return (
    <div>
      <Col lg xs={12}>
        <Row className="m-0 pb-5 card__homepage">
          <div
            style={{
              backgroundColor: "#E5E5F5",
              padding: width < 430 ? "0px" : "",
            }}
          >
            <Row className="m-4">
              {/* <Row> */}
              <Col lg={10}>
                <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
                  It's never been easier to get insurance cover.{" "}
                </h3>
                <h3
                  style={{
                    fontSize: "16px",
                    color: "#2F2E80",
                    paddingRight: "30%",
                  }}
                >
                  If the education of your child/ward means anything to you,
                  then you would go the extra mile to ensure they enjoy the very
                  best, come what may. That is what our FlexiEdu plans are all
                  about.
                </h3>
              </Col>
              <Col lg={2} className="justify-content-right">
                <Row>
                  <h6
                    style={{
                      color: "#56616C",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Policy underwritten by
                  </h6>
                </Row>
                <Row>
                  <div
                    className={`d-flex ${
                      width > 770
                        ? "justify-content-start"
                        : "justify-content-center"
                    }`}
                  >
                    <img
                      src={sanlam}
                      style={{
                        width: "100px",
                        marginLeft: 36.2,
                        marginTop: "1px",
                      }}
                      alt="sanlam"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <hr
                style={{
                  color: "white",
                  height: 2,
                  width: "97%",
                  margin: "auto",
                }}
              />
            </Row>

            <Row className="secondary-container p-3 ">
              <Row className="secondary-container p-3 ">
                <Col lg={7}>
                  <Row>
                    <Col lg={3} style={{ textAlign: "right" }}>
                      <img
                        src={Educare}
                        style={{
                          width: "90%",
                        }}
                        alt="box"
                      />
                    </Col>
                    <Col lg={9}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "#2F2E80",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        FlexiEdu Plan
                      </h4>
                      <span style={{ color: "#2F2E80", fontWeight: "bold" }}>
                        Product Information:
                      </span>
                      <ul>
                        {data2.map((d) => (
                          <li
                            style={{
                              color: "#2F2E80",
                              paddingRight: "13%",
                              marginTop: "2px",
                            }}
                          >
                            <span>{d}</span>
                          </li>
                        ))}
                      </ul>
                      <h5
                        style={{
                          color: "#2F2E80",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Required documents
                      </h5>
                      <span style={{ color: "#2F2E80", fontWeight: "bold" }}>
                        Proof of Identification
                      </span>
                      &nbsp;
                      <span style={{ color: "#2F2E80" }}>e.g</span>
                      &nbsp;&nbsp;
                      <span style={{ color: "#2F2E80" }}>
                        Driver's License / NIN / Voter's Card / International
                        Passport (Only 1 required )
                      </span>
                      <p style={{marginTop:"8px"}}>
                        <span style={{ color: "#2F2E80", fontWeight: "bold" }}>Utility bill</span>{" "}
                        &nbsp;
                        <span style={{ color: "#2F2E80" }}>
                          e.g
                        </span>
                        &nbsp;&nbsp;
                        <span style={{ color: "#2F2E80" }}>
                          PHCN Bill / Waste Management Bill (within past 3
                          months)
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={5}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"
                    style={{ height: "100%" }}
                  >
                    <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        FlexiEdu Plan
                      </h4>
                    </Row>
                    <Row>
                      <h4
                        style={{
                          fontSize: "14pt",
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Create an education fund for your child's future
                        <br />
                      </h4>
                    </Row>
                    <Row>
                      <div className="homepage_dob_container">
                        <p className="text-white f-700 m-0">
                          When were you born?*
                        </p>
                        <input
                          type="text"
                          max={calculateMinDate()}
                          style={{ margin: 5, width: "250px", height: "34px" }}
                          onChange={(e) => {
                            dispatch(
                              updateCustomAttributes({
                                type: "CUSTOM_ATTRIBUTES",
                                payload: {
                                  ...customAttributes,
                                  dateOfBirth: e.target.value,
                                },
                              })
                            );
                            setDOB(e.target.value);
                          }}
                          placeholder={"dd/mm/yyyy"}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "date")}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          value={dob}
                          min={"1962-01-01"}
                        />
                        {errors?.dob ? (
                          <span className="errors" style={{ color: "#fff" }}>
                            {errors?.dob?.[0]}
                          </span>
                        ) : (
                          <></>
                        )}

                        <p className="text-white f-700 mt-3 mb-0">
                          What is your expected maturity amount?
                        </p>
                        <input
                          type="text"
                          style={{
                            margin: 5,
                            fontSize: 14,
                            width: "250px",
                            height: "34px",
                          }}
                          onBlurCapture={handleBlur}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            dispatch(
                              updateCustomAttributes({
                                type: "CUSTOM_ATTRIBUTES",
                                payload: {
                                  ...customAttributes,
                                  sumAssured: e.target.value,
                                },
                              })
                            );
                            setSum(e.target.value);
                          }}
                          value={sum}
                          placeholder="What is your expected maturity amount?"
                        />
                        {errors?.sum ? (
                          <span className="errors" style={{ color: "#fff" }}>
                            {errors?.sum?.[0]}
                          </span>
                        ) : (
                          <></>
                        )}

                        <p className="text-white f-700 mt-3 mb-0">
                          Choose cover duration: [{sliderValue} yrs]:
                        </p>
                        <ReactSlider
                          min={5}
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          max={20}
                          value={sliderValue}
                          onChange={(step) => {
                            dispatch(
                              updateCustomAttributes({
                                type: "CUSTOM_ATTRIBUTES",
                                payload: {
                                  ...customAttributes,
                                  duration: step,
                                },
                              })
                            );
                            setSliderValue(step);
                          }}
                        />
                        <div className="w-100 marker d-flex justify-content-between align-items-center flex-row">
                          <p>5 yr</p>
                          <p>10 yr</p>
                          <p>15 yr</p>
                          <p>20 yr</p>
                        </div>

                        {errors?.cover ? (
                          <span className="errors" style={{ color: "#fff" }}>
                            {errors?.cover?.[0]}
                          </span>
                        ) : (
                          <></>
                        )}
                        <button onClick={handleSubmit}>
                          {isLoading ? "Quoting..." : "Get a quote"}
                        </button>
                      </div>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginTop: "8px",
                        fontWeight: "bold",
                        marginBottom: "25px",
                      }}
                    >
                      From: ₦5,000 / Monthly
                    </Row>
                    <hr
                      style={{
                        color: "white",
                        height: 1,
                        width: "97%",
                        margin: "auto",
                        marginTop: "20px",
                      }}
                    />
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "8pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        padding: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      By opting to buy insurance, Letshego will share the
                      required information with the insurer to facilitate your
                      policy purchase.
                    </Row>
                    <Row>
                      <h6
                        style={{
                          color: "white",
                          fontSize: "8pt",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        Policy underwritten by
                      </h6>
                    </Row>
                    <Row>
                      <div
                        className={`d-flex ${
                          width > 770
                            ? "justify-content-start"
                            : "justify-content-center"
                        }`}
                      >
                        <img
                          src={sanlam}
                          style={{
                            marginBottom: 20,
                            width: "95px",
                            marginTop: "1px",
                          }}
                          alt="sanlam"
                        />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Row>
      </Col>
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

const data2 = [
  'You must be 18 years or older',
  'Max age to qualify is 60 years', 
  'Exit age is 65 years',
  'Min policy duration: 5 years',
  'Min monthly premium of ₦5,000',
  'No waiting period for accidental death',
  '6 Month waiting period for death by natural causes',
  'All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.',
];
const data3 = [
  "Driving licence",
  "National ID",
  "International Passport",
  "Permanent Voters Card",
];
const data4 = [
  "Electricity Bill",
  "Water Bill",
  "Tenancy Agreement",
  "Waste Management Bill",
];
const data5 =[
  'All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.',
]


const schema = {
  dob: {
    presence: {
      allowEmpty: false,
    },
   
  },
  sum: {
    presence: {
      allowEmpty: false,
    },
  },
  cover: {
    presence: {
      allowEmpty: false,
    },
    numericality: {
      greaterThanOrEqualTo: 5,
    }
  }
}