import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dropdown, RadioList, TabBar, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  updateCustomAttributes,
  executeCalculator,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { radioChecked, radioUnchecked } from "../../../assets/images";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { trackUserEvents } from "../../../utils/utils";


const Step1 = ({ handleNext, handleBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const history = useNavigate();
  const dispatch = useDispatch();

  const factors = useSelector((state) => state?.products?.factors?.attributes);
  const riskAttributes = useSelector((state) => state?.products?.riskAttributes);
  const productTypes = useSelector((state) => state?.products?.types);
  const customAttributes = useSelector((state) => state?.products?.customAttribues);
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const [errors, setErrors] = useState({});


  const handleSubmit = async() => {
    var error = validate(
      {
        paymentFrequency: factors?.find((x) => x.name === "Payment Frequency")
          ?.value,
      },
      {
        paymentFrequency: {
          presence: {
            allowEmpty: false,
          },
        },
      }
    );

    if (error || error !== undefined) {
      return setErrors(error);
    }
    const res = await dispatch(
      executeCalculator({
        payload: {
          factors: factors?.map((y) => ({
            dataType: y.dataType,
            name: y.name,
            instanceId: y.instanceId,
            value:
              y.name === "Duration"
                ? customAttributes.duration ?? 5
                : y.name === "Sum Assured"
                ? customAttributes.sumAssured?.replace(",", "")
                : y.name === "Date of Birth"
                ? customAttributes.dateOfBirth
                : y.name === "Payment Frequency"
                ? y.value.trim() === "SinglePremium Price"
                  ? "Single Premium"
                  : y.value.trim() === "Single Premium Price"
                  ? "Single Premium"
                  : y.value?.trim().replace("Price", "").trim()
                : null,
          })),
          productTypeReference: productTypes.types[0].instanceId,
          risks: [
            {
              ...riskAttributes?.find((x) => x.code === "MAM"),
              attributes: [
                ...riskAttributes
                  ?.find((x) => x.code === "MAM")
                  ?.attributes.map((y) => ({
                    value:
                      y.name === "Source of Fund"
                        ? "Salary"
                        : y.name === "Religion"
                        ? "None"
                          ? y.name === "BVN Number"
                          : "NA"
                        : "",
                    dataType: y.dataType,
                    name: y.name,
                    instanceId: y.instanceId,
                  })),
              ],
            },
          ],
        },
      })
    );

    //trackUserEvents
    trackUserEvents("ng_edu_insurance_quote_cta", {
      paymentFrequency: factors?.find((x) => x.name === "Payment Frequency")?.value,
      payment: parseString(calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message)
        ?.[`${factors?.find((x) => x.name === "Payment Frequency")?.value}`]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    });
    handleNext();
    setIsLoading(false);
  };

    const handleBackTrack = () => {
      history("/");
      //trackUserEvents
      trackUserEvents("ng_edu_insurance_quote_cta", {});
    };
  const DATA = [
    {
      name: "Monthly",
      amount: 100,
    },
    {
      name: "Quaterly",
      amount: 350,
    },
    {
      name: "Bi-annual",
      amount: 450,
    },
    {
      name: "Annual",
      amount: 800,
    },
    {
      name: "Single premium",
      amount: 2000,
    },
  ];

  const parseString = (string) => {
    const arr = string?.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  return 1 ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">FlexiEdu Plan</h3>
            <h2 className="primary-color f-800">Quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">FlexiEdu Plan</p>
          <h2 className="primary-color f-800">Quote</h2>
        </div>
      )}

      <div className="d-flex justify-content-center">
        <div className={style.main_container}>
          <h5 className="text-white f-700">FlexiEdu Plan</h5>
          <div
            className={classNames(
              style.sub_container,
              "d-flex justify-content-around"
            )}
          >
            <div>
              <h5 className="yellow f-700">Sum assured</h5>
              {/* <p className="text-center f-700 primary-color">
                ₦
                {parseFloat(factors
                  ?.find((x) => x.name === "Sum Assured")
                  ?.value)?.toFixed(2)?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p> */}
              <p className="text-center f-700 primary-color">
                ₦
                {parseFloat(
                  calculatorResponse?.productOptions?.[0]?.risks?.[0]
                    ?.valuations?.values?.[0]?.value)
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
            <div>
              <h5 className="yellow f-700">Duration</h5>
              <p className="text-center f-700 primary-color">
                {/* {factors?.find((x) => x.name === "Duration")?.value} years */}
                {customAttributes?.duration} years
              </p>
            </div>
          </div>

          <div className={classNames(style.sub_container)}>
            {Object.keys(
              parseString(
                calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                  ?.message
              )
            )?.[0] === "Price has been calculated" ||
            Object.keys(
              parseString(
                calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                  ?.message
              )
            )?.[0] === "No cover is available for the given duration and age" ||
            calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message.includes(
              "Please increase your sum assured to",
              "since the minimum premium is"
            ) ? (
              <>
                <h6 className="primary-color f-700 text-center">
                  {calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message.includes(
                    "Please increase your sum assured to",
                    "since the minimum premium is"
                  )
                    ? calculatorResponse?.productOptions?.[0]?.risks?.[0]
                        ?.rating?.message
                    : Object.keys(
                        parseString(
                          calculatorResponse?.productOptions?.[0]?.risks?.[0]
                            ?.rating?.message
                        )
                      )?.[0]}
                </h6>
              </>
            ) : (
              <>
                <h5 className="yellow f-700 text-center">
                  Choose the payment option
                </h5>
                <Row>
                  <Col xs={7}>
                    <p className="text-center primary-color">
                      Payment frequency
                    </p>
                  </Col>
                  <Col xs={5}>
                    <p className="text-center primary-color">Payment</p>
                  </Col>
                </Row>
                {Object.keys(
                  parseString(
                    calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                      ?.message
                  )
                ).map((item) => (
                  <Row>
                    <Col className="d-flex justify-content-start">
                      <RadioList
                        title={
                          item?.includes("SinglePremium")
                            ? width > 1024
                              ? "Single Premium"
                              : "SinglePremium"
                            : item?.replace("Price", "")
                        }
                        name={item}
                        checked={
                          factors?.find((x) => x.name === "Payment Frequency")
                            ?.value
                        }
                        onClick={(e) => {
                          var arr = [...factors];
                          const idx = factors?.findIndex(
                            (x) => x.name === "Payment Frequency"
                          );
                          arr[idx] = { ...arr[idx], value: e };
                          dispatch(
                            updateCustomAttributes({
                              type: "FACTORS",
                              payload: arr,
                            })
                          );
                        }}
                      />
                    </Col>
                    {/* <Col className="d-flex justify-content-center"> */}
                    <Col
                      className={
                        width > 1024
                          ? "d-flex justify-content-center"
                          : "d-flex justify-content-end"
                      }
                    >
                      <p className="primary-color f-700">
                        ₦
                        {parseString(
                          calculatorResponse?.productOptions?.[0]?.risks?.[0]
                            ?.rating?.message
                        )
                          ?.[`${item}`]?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </Col>
                  </Row>
                ))}
                {errors?.paymentFrequency ? (
                  <span className="errors">{errors?.paymentFrequency[0]}</span>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <NavigationButtons
        isBackDisabled={isLoading}
        loading={isLoading}
        // back={() => history("/")}
        back={handleBackTrack}
        next={handleSubmit}
        disabled={
          Object.keys(
            parseString(
              calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                ?.message
            )
          )?.[0] === "Price has been calculated" ||
          Object.keys(
            parseString(
              calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                ?.message
            )
          )?.[0] === "No cover is available for the given duration and age" ||
          calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message.includes(
            "Please increase your sum assured to",
            "since the minimum premium is"
          )
        }
      />
    </div>
  ) : (
    <Loader />
  );
};

export default Step1;
