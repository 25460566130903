import React, { useEffect, useState, useCallback } from "react";

// Form Module css
import style from "./Form.module.css";

// MUI Components
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import classNames from "classnames";

import { useNavigate } from "react-router";

// Importing Forms

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

import { UpdateSteps } from "../../store/actions/products";
import useWindowDimensions from "../../utils/useWindowDimension";
import { toast } from "react-toastify";
import Step1 from "./Steps/Step-1";
import Step2 from "./Steps/Step-2";
import Step3 from "./Steps/Step-3";

const Form = () => {
  // Dynamic width from Screen sizes
  const { width } = useWindowDimensions();

  // Initial steps or presisted if user resume
  const intitalStep = useSelector((state) => state.products.activeStep);

  const navigate = useNavigate();

  // Active steps according to forms
  const [activeStep, setActiveStep] = useState(intitalStep || 0);

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );

  useEffect(() => {
    if (riskAttributes?.length < 1) {
      navigate("/");
    }
  }, [riskAttributes]);

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Dispatch Hooks
  const dispatch = useDispatch();

  // Function performs Next Step
  const handleNext = (step) => {
    window.scrollTo(0, 0);
    if (typeof step === "number") {
      return setActiveStep(step);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Function performs previous Step
  const handleBack = (step) => {
    if (typeof step === "number") {
      return setActiveStep(step);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Updating Active steps to the store
  useEffect(() => {
    dispatch(UpdateSteps(activeStep));
  }, [activeStep, dispatch]);

  document.body.style = "background: #3C409610;";

  const getCompleted = () => {
    switch (activeStep) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 1;
      case 4:
        return 1;
      case 5:
        return 2;
      default:
        return 0;
    }
  };
  const [files, setFiles] = useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);

  const handleFileUpload = (e) => {
    const { name } = e.target;
    var obj = { ...files };
    if (name && (e.target.files[0].size / 1000000 >= 2)) {
      obj = {
        ...files,
        [name]: "",
      };
      setFiles({ ...obj });
      forceUpdate();
      return toast.error("Max file size 2mb");
    }
    if (name) {
      obj = {
        ...files,
        [name]: e.target.files[0],
      };
    }
    setFiles({ ...obj });
  };

  const handleFormBack = (step) => {
    setActiveStep(step);
  };

  return !isLoading ? (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Educational Cover in a few clicks
            </h3>

            {/* <------- STEPPER STARTS --------> */}

            <Box className="mt-5">
              <Stepper activeStep={getCompleted()} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel completed={getCompleted(index)}>
                      {width > 430 ? label.label : ""}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {/* <------- STEPPER ENDS --------> */}
          </>
        ) : null}

        {/* <------- FORMS STARTS --------> */}

        <div className={classNames("container", style.boxBorder)}>
          {activeStep === 0 && (
            <Step1 handleBack={handleBack} handleNext={handleNext} />
          )}

          {activeStep === 1 && (
            <Step2
              handleBack={handleBack}
              handleNext={handleNext}
              files={files}
              handleFileUpload={handleFileUpload}
            />
          )}
          {activeStep === 2 && (
            <Step3
              handleBack={handleBack}
              handleNext={handleNext}
              files={files}
              handleFormBack={handleFormBack}
            />
          )}
        </div>
        {/* <------- FORMS ENDS --------> */}
      </div>
    </div>
  ) : null;
};

const steps = [
  {
    label: "Select Cover",
  },
  {
    label: "Complete your details",
  },
  {
    label: "Application & payment details",
  },
];

export default Form;
